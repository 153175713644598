export const DEFAULT_SIGNIFICANCE_LEVEL = 0.05;
export const COHORT_DATE = {
  INSTALL_DATE: "install_date_tzutc",
  ONBOARDING_DATE: "first_signal_date",
};

export const COHORT_DATE_LABEL = {
  INSTALL_DATE: "Install Date",
  ONBOARDING_DATE: "Onboarding Date",
};
