import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, InputNumber } from "antd";
import { FormInstance } from "antd/lib/form/Form";

export const MaxDayDiffFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <Form.Item
      className="mx-1"
      label="Max Day diff"
      name={FilterFormFieldsEnum.MAX_DAY_DIFF}
      required
      rules={[
        {
          message: "Please select a max day diff",
          required: true,
        },
      ]}
    >
      <InputNumber
        min={0}
        placeholder="Select max day diff"
        style={{
          width: 100,
        }}
      />
    </Form.Item>
  );
};
