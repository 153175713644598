import React from "react";
import { createContext, useContext } from "react";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_SIGNIFICANCE_LEVEL } from "@src/pages/ab-test-explore/contanst";

type MetricPerformanceContextType = {
  activeKey: string | undefined;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  form: FormInstance<any>;
  getValueTabItemOfTargetKey: (targetKey: string) => any;
  handleUpdateMetricTabItems: (targetKey: string, fieldToUpdate: string | string[]) => void;
  removeMetricTabItems: (targetKey: string, fieldToUpdate: string) => void;
  add: (_value?: Record<string, any>, _label?: string) => void;
  remove: (targetKey: TargetKey) => void;
};
export const MetricPerformanceContext = createContext({} as MetricPerformanceContextType);

type IProps = {
  children: React.ReactNode;
  form: FormInstance<any>;
};

export type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export const generateKey = () => uuidv4();

export const MetricPerformanceContextProvider: React.FC<IProps> = ({ children, form }) => {
  const tabIndex = React.useRef(0);
  const [activeKey, setActiveKey] = React.useState<string>();
  const generateLabel = () => {
    tabIndex.current += 1;
    return `Metric ${tabIndex.current}`;
  };

  const getValueTabItemOfTargetKey = React.useCallback(
    (targetKey: string) => {
      const metricTabItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];
      return metricTabItems.find((item: any) => item.key === targetKey);
    },
    [form],
  );

  const handleUpdateMetricTabItems = React.useCallback(
    (targetKey: string, _fieldToUpdate: string | string[]) => {
      const metricTabItem = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];

      const newMetricItem = metricTabItem.map((item: any) => {
        if (item.key !== targetKey) return item;
        const fieldToUpdate = typeof _fieldToUpdate === "string" ? [_fieldToUpdate] : _fieldToUpdate;
        const _item = item;
        fieldToUpdate.forEach((field) => {
          _item[field] = form.getFieldValue([targetKey, field]);
        });
        return _item;
      });

      form.setFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS, newMetricItem);
    },
    [form],
  );

  const removeMetricTabItems = React.useCallback(
    (targetKey: string, fieldToUpdate: string) => {
      const metricTabItem = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];

      const newMetricItem = metricTabItem.map((item: any) => {
        if (item.key !== targetKey) return item;
        delete item[fieldToUpdate];
        return item;
      });
      console.log(JSON.parse(JSON.stringify(newMetricItem)));

      form.setFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS, newMetricItem);
    },
    [form],
  );

  const add = React.useCallback(
    (_value?: Record<string, any>, _label?: string) => {
      const metricItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];
      const newActiveKey = generateKey();
      const tabLabel = _label ? `${_label} Copy` : generateLabel();

      const value = _value
        ? {
            ..._value,
            [ChartFormFieldsEnum.METRIC_TAB_NAME]: tabLabel,
          }
        : {
            [ChartFormFieldsEnum.METRIC_TAB_NAME]: tabLabel,
            [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: DEFAULT_SIGNIFICANCE_LEVEL,
          };

      form.setFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS, [
        ...metricItems,
        {
          key: newActiveKey,
          ...value,
        },
      ]);
      form.setFieldValue(newActiveKey, value);
      setActiveKey(newActiveKey);
    },
    [form],
  );

  const remove = (targetKey: TargetKey) => {
    const metricItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];
    const targetIndex = metricItems.findIndex((pane: any) => pane.key === targetKey);
    const newPanes = metricItems.filter((pane: any) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    form.setFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS, newPanes);
  };

  React.useEffect(() => {
    const initMetricTabItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS);
    if (initMetricTabItems) {
      tabIndex.current = initMetricTabItems.length;
      return;
    }
    setTimeout(() => {
      add();
    }, 0);
  }, [form, add]);

  const values = {
    activeKey,
    setActiveKey,
    form,
    getValueTabItemOfTargetKey,
    removeMetricTabItems,
    handleUpdateMetricTabItems,
    add,
    remove,
  };
  return <MetricPerformanceContext.Provider value={values}>{children}</MetricPerformanceContext.Provider>;
};

export const useMetricPerformanceContext = () => useContext(MetricPerformanceContext);
