import React, { FC } from "react";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import QueryBuilder, { Field, ValueEditorProps } from "react-querybuilder";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";

import { Form, Input, Modal, Select } from "antd";
import { CustomFieldSelector } from "./custom-field-selector";

type FilterModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const _FilterModal: React.FC<FilterModalProps> = ({ targetKey, open, setOpen }) => {
  const { form } = useMetricPerformanceContext();
  const { getAvailableDimensions } = useAbTestExplorationContext();

  const filterWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_FILTER], form);
  const targetedModel = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);

  const availableDimensions = getAvailableDimensions({ modelAlias: targetedModel }) || [];

  const [newQuery, setNewQuery] = React.useState<any>({
    combinator: "and",
    rules: [],
  });

  React.useEffect(() => {
    if (!open) return;
    setNewQuery(form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER]));
  }, [form, open, targetKey]);

  const valueEditor = React.useCallback((props: ValueEditorProps) => {
    const { handleOnChange, value, operator, inputType } = props;
    if (operator === "=" && inputType === "number") {
      return (
        <Input
          value={value}
          style={{ width: 200 }}
          placeholder="Please input value"
          onChange={(v) => {
            handleOnChange(v.target.value);
          }}
        />
      );
    }
    if (operator === "is_set" || operator === "is_not_set") {
      return null
    }
    return (
      <SelectWithSpinAndCustomMaxTag
        {...(Boolean(value) && { value: value })}
        style={{ width: 200 }}
        mode="tags"
        placeholder="Please input value"
        onChange={(_value) => handleOnChange(_value)}
      />
    );
  }, []);
  const handleOk = () => {
    const queryToSave = {
      ...newQuery,
      rules: newQuery?.rules?.filter((rule: any) => {
        console.log(rule)
        if (rule.operator === "is_set" || rule.operator === "is_not_set" ) {
          // return rule.value === undefined
          return true
        }
        if (typeof rule.value === "string") return !!rule.value;
        return rule.value?.length > 0;
      }),
    };
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], queryToSave);
    setOpen(false);
  };
  return (
    <div className="flex justify-center items-center cursor-pointer">
      <div
        className="hover:text-blue-600 text-gray-500"
        onClick={() => {
          setOpen(true);
        }}
      >
        <FilterOutlined className="mr-1" />
        Filter ({filterWatch?.rules?.length || 0})
      </div>
      <Modal
        open={open}
        closeIcon={<CloseOutlined onClick={() => setOpen(false)} className="p-2 " />}
        footer={(
          _: React.ReactNode,
          extra: {
            OkBtn: FC;
            CancelBtn: FC;
          },
        ) => {
          return (
            <div className="flex justify-end">
              <div onClick={() => setOpen(false)}>
                <extra.CancelBtn />
              </div>
              <div className="mx-1" />
              <div onClick={handleOk}>
                <extra.OkBtn />
              </div>
            </div>
          );
        }}
        width={800}
        title="Metric filter"
        centered
      >
        <QueryBuilderAntD>
          <QueryBuilder
            key={targetKey}
            query={newQuery}
            fields={availableDimensions.map((r) => {
              const { name, field_type, matched_regex, options } = r;
              const toBuilderType = (t?: string) => {
                if (!t) return "text";
                else if (t.toLowerCase() === "string") return "text";
                else if (t.toLowerCase() === "int64" || t.toLowerCase() === "float64") return "number";
                else if (t.toLowerCase() === "date") return "date";
                else return "text";
              };

              const inputType = toBuilderType(field_type);
              return {
                name,
                label: name,
                matched_regex: matched_regex,
                inputType,
                required: Boolean(options?.filter?.required),
              };
            })}
            getOperators={(field: string, misc: { fieldData: Field }) => {
              // dynamic member currently does not has enough meta to detect supported operators
              if (misc.fieldData === undefined) return [{ name: "=", label: "=" }];

              const { inputType } = misc.fieldData;

              const getOperators = (t?: string) => {
                const _operators = [
                  { name: "is_set", label: "Is set" },
                  { name: "is_not_set", label: "Is not set" },
                  { name: "=", label: "=" }
                ]
                if (t === "text") {
                  //skip
                }
                else if (t === "date" || t === "number") (
                  _operators.push(...[
                    { name: "between", label: "between" },
                  ])
                )

                return _operators
              };
              const operators = getOperators(inputType as string);

              return operators;
            }}
            controlElements={{
              fieldSelector: CustomFieldSelector,
              valueEditor: valueEditor,
              combinatorSelector: () => {
                return (
                  <Select
                    style={{ width: 80 }}
                    defaultValue={"and"}
                    disabled
                    options={[{ name: "and", label: "AND", value: "and" }]}
                  />
                );
              },
            }}
            controlClassnames={{
              addGroup: "hidden",
            }}
            onQueryChange={(query: any) => {
              setNewQuery(query);
            }}
          />
        </QueryBuilderAntD>
      </Modal>
      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_FILTER]}>
        <Input />
      </Form.Item>
    </div>
  );
};

export const FilterModal = React.memo(_FilterModal);
