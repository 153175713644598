import React from "react";
import { ChartActionProps } from ".";
import { Button, Modal } from "antd";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";

import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";
import type { DescriptionsProps } from "antd";
import { isObject } from "lodash";
import { Table } from "antd";
const titleCase = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

const returnPlaceHolderIfNullOrUndefined = (input: any, placeHolder: string) => {
  return input && input !== null ? input : placeHolder;
};

const stringifyIfObject = (input: any) => {
  return isObject(input) ? JSON.stringify(input) : input;
};

export const JobMeta: React.FC<ChartActionProps> = ({ tabKey }) => {
  const { jobsMeta, jobsElapsed } = useChartWrapperContext();
  const [open, setOpen] = React.useState(false);
  const meta = jobsMeta[tabKey];
  const elapsed = jobsElapsed[tabKey];
  if (!meta)
    return (
      <Button type="text" disabled={true} className="w-full flex justify-start items-center">
        <ConsoleSqlOutlined className="pb-1 text-[16px]" />
        View Job meta
      </Button>
    );

  const items: DescriptionsProps["items"] = [
    {
      key: "elapsed",
      label: "Elapsed time",
      children: `${elapsed || "__"} (ms)`,
      span: 3,
    },
    {
      key: "stats",
      label: "Statistic",
      children: (
        <Descriptions
          bordered
          items={Object.keys(meta?.stats || []).map((k) => {
            return {
              key: `stats-${k}`,
              label: titleCase(k),
              children: `${returnPlaceHolderIfNullOrUndefined(stringifyIfObject(meta.stats[k]), "-")}`,
              span: 3,
            };
          })}
        />
      ),
      span: 3,
    },

    {
      key: "schema",
      label: "Schema",
      children: (
        <Table
          id="id"
          rowKey="id"
          sticky={{
            offsetHeader: 0,
          }}
          size="small"
          pagination={false}
          dataSource={(meta?.schema || []).map((item, idx) => {
            return {
              id: idx,
              ...item,
            };
          })}
          columns={[
            {
              key: "name",
              dataIndex: "name",
              title: "Column name",
            },
            {
              key: "field_type",
              dataIndex: "field_type",
              title: "Field type",
            },
            {
              key: "mode",
              dataIndex: "mode",
              title: "Mode",
            },
          ]}
        />
      ),
      span: 3,
    },
  ];

  return (
    <div className="">
      <Button
        type="text"
        disabled={!meta && !elapsed}
        onClick={() => setOpen(true)}
        className="w-full flex justify-start items-center"
      >
        <ConsoleSqlOutlined className="pb-1 text-[16px]" />
        View Job meta
      </Button>
      <Modal
        title="Optimized Query"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setOpen(false);
        }}
        width={800}
      >
        <div className="h-[600px] overflow-y-scroll">
          <Descriptions title="User Info" bordered items={items} />
        </div>
      </Modal>
    </div>
  );
};
