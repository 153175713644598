import { IQueryFilter, IQueryOptions } from "@src/types/ab-testing-exploration";
import { SimpleReport } from "./report-simple";

type ReportDataDetailProps = {
  targetKey: string;
  jsonQueryFilter: IQueryFilter[];
  queryOptions: IQueryOptions;
};
export const ReportDetail: React.FC<ReportDataDetailProps> = ({ targetKey, jsonQueryFilter, queryOptions }) => {
  return <SimpleReport targetKey={targetKey} queryOptions={queryOptions} jsonQueryFilter={jsonQueryFilter} />;
};
