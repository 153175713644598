import { Checkbox, Form, FormInstance, Modal } from "antd";
import React from "react";
import { ExtraFieldModalContent } from "./modal-content";
import { css } from "@emotion/react";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { ALL_EXTRA_FIELDS_AS_DATE, ALL_EXTRA_FIELDS_FILTER } from "@src/pages/ab-test-explore/contanst/filter-fields";
import { ExtraButton } from "./extra-button";
import { ModalFooter } from "./modal-footer";
import { convertDateRangeToDayJS } from "@src/util/time-form";

export const ExtraFilterMain: React.FC<{ form: FormInstance }> = ({ form }) => {
  const isModalOpen = Form.useWatch(FilterFormFieldsEnum.IS_OPEN_MODAL, form);

  const handleWhenModalOpen = (_open: boolean) => {
    if (!_open) return;
    const extraFilerValuesConfirmed = form.getFieldValue(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED);
    const extraFilerValuesConfirmedParsed = JSON.parse(extraFilerValuesConfirmed ?? "{}");

    if (extraFilerValuesConfirmed === undefined) return;
    const valuesExtraForm: Record<string, any> = {};
    ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
      if (ALL_EXTRA_FIELDS_AS_DATE.includes(field)) {
        valuesExtraForm[field] = convertDateRangeToDayJS(extraFilerValuesConfirmedParsed[field]);
      } else {
        valuesExtraForm[field] = extraFilerValuesConfirmedParsed[field];
      }
    });
    form.setFieldsValue(valuesExtraForm);
  };

  return (
    <>
      <Form.Item className="pt-7">
        <ExtraButton form={form} />
      </Form.Item>
      <Modal
        css={css`
          .ant-modal-content {
            padding: 0;
          }
        `}
        style={{ maxWidth: 1400 }}
        title={<div className="bg-blue-700 text-white p-4 rounded-t-[6px]">Extra general info filter</div>}
        footer={() => {
          return <ModalFooter form={form} />;
        }}
        width={"90%"}
        open={isModalOpen}
        centered
        onCancel={() => form.setFieldValue(FilterFormFieldsEnum.IS_OPEN_MODAL, false)}
        afterOpenChange={handleWhenModalOpen}
      >
        <div className="px-4 ">
          <ExtraFieldModalContent form={form} />
        </div>
      </Modal>
      <Form.Item name={FilterFormFieldsEnum.IS_SHOW_CHIP} hidden valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_OPEN_MODAL} hidden valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </>
  );
};
