import { FormInstance } from "antd/lib/form/Form";
import { Typography, Table, Collapse, Spin } from "antd";
import React from "react";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { EChartWrapper } from "../layout/echart-wrapper";
import ReactECharts from "echarts-for-react";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { IQuery } from "@src/types/ab-testing-exploration";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { AbTestUserDistributionMemo } from "./user-distribution";
import { ChartLayoutWithUserPercent } from "../layout/chart-layout-with-user-percent";
import { ChartDataTypeEnum, useChartLayout } from "@src/hooks/ab-testing/use-chart-layout";
import { formatNumber } from "@src/util/number-format";

const { Text } = Typography;

const UserOverview: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { chartDataType, setChartDataType } = useChartLayout();
  const generalFieldValues = useGetGeneralField({ form });
  const { productCode, generalFilters, explorationOptions, extraFilters, invalidUserFilters } = generalFieldValues;
  const [activePanelKeys, setActivePanelKeys] = React.useState<string[]>([]);

  const jsonQuery: IQuery = {
    dimensions: [],
    measures: ["user_count"],
    limit: 10000,
  };

  const modelAlias = SupportedModelAlias.USER_INFO;

  const { result: generalUserCountResult, isLoading: generalUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: generalFilters,
    },
    options: explorationOptions,
    isSkip: activePanelKeys.indexOf("user-overview") === -1,
  });

  const generalUserCount =
    generalUserCountResult && generalUserCountResult.length > 0 ? generalUserCountResult[0]["user_count"] : 0;

  const { result: extraFilterUserCountResult, isLoading: extraFilterUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: [...generalFilters, ...extraFilters],
    },
    options: explorationOptions,
    isSkip: activePanelKeys.indexOf("user-overview") === -1,
  });

  const extraFilterUserCount =
    extraFilterUserCountResult && extraFilterUserCountResult.length > 0
      ? extraFilterUserCountResult[0]["user_count"]
      : 0;

  const { result: invalidUserCountResult, isLoading: invalidUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: [...generalFilters, ...extraFilters, ...invalidUserFilters],
    },
    options: explorationOptions,
  });

  const invalidUserCount =
    invalidUserCountResult && invalidUserCountResult.length > 0 ? invalidUserCountResult[0]["user_count"] : 0;

  const isLoading = generalUserCountIsLoading || invalidUserCountIsLoading || extraFilterUserCountIsLoading;

  const result = [
    {
      step: "General filters",
      user_count: generalUserCount,
      filtered_user_count: 0,
      user_count_ratio: 100,
      filtered_user_count_ratio: 0,
    },
    {
      step: "Extra filters",
      user_count: extraFilterUserCount,
      filtered_user_count: generalUserCount - extraFilterUserCount,
      user_count_ratio: ((extraFilterUserCount / generalUserCount) * 100).toFixed(2),
      filtered_user_count_ratio: (((generalUserCount - extraFilterUserCount) / generalUserCount) * 100).toFixed(2),
    },
    {
      step: "Abalyzer valid user rules",
      user_count: invalidUserCount,
      filtered_user_count: extraFilterUserCount - invalidUserCount,
      user_count_ratio: ((invalidUserCount / generalUserCount) * 100).toFixed(2),
      filtered_user_count_ratio: (((extraFilterUserCount - invalidUserCount) / generalUserCount) * 100).toFixed(2),
    },
  ];

  const columns = [
    {
      key: "step",
      dataIndex: "step",
      title: "Filter step",
    },
    {
      key: "user_count",
      dataIndex: "user_count",
      title: "User count",
    },
    {
      key: "user_count_ratio",
      dataIndex: "user_count_ratio",
      title: "User percent",
      render: (value: number) => `${value}%`,
    },
    // {
    //   key: "filtered_user_count",
    //   dataIndex: "filtered_user_count",
    //   title: "Invalid user count",
    // },
    // {
    //   key: "filtered_user_count_ratio",
    //   dataIndex: "filtered_user_count_ratio",
    //   title: "Invalid user percent",
    //   render: (value: number) => `${value}%`,
    // },
  ];
  const option: echarts.EChartsOption = {
    legend: {},
    tooltip: {
      position: "top",
      trigger: "axis",
      formatter: (params: any) => {
        let stringTemplate = "";
        params?.forEach((param: any) => {
          const { user_count, filtered_user_count, user_count_ratio, filtered_user_count_ratio } = param?.data ?? {};
          const validUserValue =
            chartDataType === ChartDataTypeEnum.USER_COUNT ? formatNumber(user_count) : user_count_ratio + "%";
          const filteredUserValue =
            chartDataType === ChartDataTypeEnum.USER_COUNT
              ? formatNumber(filtered_user_count)
              : filtered_user_count_ratio + "%";
          stringTemplate += `${param.seriesName}: ${param.seriesIndex === 0 ? validUserValue : filteredUserValue}<br>`;
        });

        return stringTemplate;
      },
    },
    grid: {
      height: "70%",
      top: "10%",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 30,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "value",
      splitArea: {
        show: true,
      },
      axisLabel: {
        formatter: (value: number) => {
          return chartDataType === ChartDataTypeEnum.USER_COUNT ? value?.toString() : value + "%";
        },
      },
    },
    dataset: [
      {
        source: result,
      },
    ],
    series: [
      {
        name: "Valid users",
        type: "bar",
        encode: {
          x: ["step"],
          y: chartDataType === ChartDataTypeEnum.USER_COUNT ? ["user_count"] : ["user_count_ratio"],
        },
        stack: "step",
      },

      {
        name: "Invalid users",
        type: "bar",
        encode: {
          x: ["step"],
          y: chartDataType === ChartDataTypeEnum.USER_COUNT ? ["filtered_user_count"] : ["filtered_user_count_ratio"],
        },
        stack: "step",
        color: "#D4D4D4",
      },
    ],
    gradientColor: ["#4bcffa", "#0fbcf9", "#6a89cc", "#4a69bd", "#1e3799"],
  };

  const isEmpty = result === undefined || (result && result.length === 0);
  return (
    <Collapse
      className="ab-testing-collapse mb-4"
      onChange={(k) => setActivePanelKeys(k as string[])}
      items={[
        {
          key: "user-overview",
          label: invalidUserCountIsLoading ? (
            <Spin />
          ) : (
            <>
              <Text strong>User count:</Text> {invalidUserCount}
            </>
          ),
          children: (
            <div>
              <ChartLayoutWithUserPercent
                loading={!!isLoading}
                chartDataType={chartDataType}
                setChartDataType={setChartDataType}
                renderRaw={
                  <EChartWrapper isEmpty={isEmpty}>
                    <Table
                      id="step"
                      rowKey="step"
                      sticky={{
                        offsetHeader: 0,
                      }}
                      size="small"
                      dataSource={result}
                      columns={columns}
                      pagination={false}
                    />
                  </EChartWrapper>
                }
                renderChart={
                  <EChartWrapper isEmpty={isEmpty}>
                    <ReactECharts option={option} style={{ height: "100%" }} />
                  </EChartWrapper>
                }
              />
              <AbTestUserDistributionMemo form={form} />
            </div>
          ),
        },
      ]}
    />
  );
};
export const UserOverviewMemo = React.memo(UserOverview);
