import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Tag } from "antd";

type BreakdownToShow = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MetricBreakdownInfoShow: React.FC<BreakdownToShow> = ({ targetKey, open, setOpen }) => {
  const { form } = useMetricPerformanceContext();
  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form) ?? [];
  if (!metricBreakdownWatch || metricBreakdownWatch.length === 0) return null;
  return (
    <div className="flex flex-wrap items-center">
      <span className="mx-2">Breakdown:</span>
      {(metricBreakdownWatch ?? [])?.map((item: any) => {
        return (
          <Tag
            key={item}
            className="my-1 cursor-pointer hover:bg-gray-300/50 duration-300"
            onClick={() => {
              setOpen(true);
            }}
          >
            {item}
          </Tag>
        );
      })}
    </div>
  );
};
