import { IJobMeta } from "@src/types/ab-testing-exploration";
import React from "react";
import { createContext, useContext } from "react";

type UpdateChartStateProps = {
  chartKey: string;
  modelAlias?: string;
  queryOption?: Record<string, any>;
  jsonQuery?: Record<string, any>;
  renderedQuery?: string;
  optimizedQuery?: string;
  meta?: IJobMeta;
  elapsed?: number;
  dataSource?: Record<string, any>;
};

type ChartWrapperContextType = {
  queries: Record<string, any>;
  setQueries: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  modelAliases: Record<string, string>;
  setModelAliases: React.Dispatch<React.SetStateAction<Record<string, string>>>;

  queryOptions: Record<string, any>;
  setQueryOptions: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  jobsElapsed: Record<string, number>;
  setJobsElapsed: React.Dispatch<React.SetStateAction<Record<string, number>>>;

  jobsMeta: Record<string, IJobMeta>;
  setJobsMeta: React.Dispatch<React.SetStateAction<Record<string, IJobMeta>>>;

  renderedQueries: Record<string, any>;
  setRenderedQueries: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  resultSets: Record<string, any>;
  setResultSets: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  dataSources: Record<string, any>;
  setDataSources: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  columns: Record<string, any>;
  setColumns: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  updateChartState: ({
    chartKey,
    renderedQuery,
    optimizedQuery,
    meta,
    elapsed,
    dataSource,
  }: UpdateChartStateProps) => void;
};
export const ChartWrapperContext = createContext({} as ChartWrapperContextType);

type IProps = {
  children: React.ReactNode;
};

export const ChartWrapperContextProvider: React.FC<IProps> = ({ children }) => {
  const [jobsElapsed, setJobsElapsed] = React.useState<Record<string, number>>({});
  const [jobsMeta, setJobsMeta] = React.useState<Record<string, IJobMeta>>({});
  const [renderedQueries, setRenderedQueries] = React.useState<Record<string, string>>({});
  const [dataSources, setDataSources] = React.useState<Record<string, any>>({});
  const [modelAliases, setModelAliases] = React.useState<Record<string, string>>({});
  const [queryOptions, setQueryOptions] = React.useState<Record<string, any>>({});
  // remove later
  // json query
  const [queries, setQueries] = React.useState<Record<string, any>>({});

  // deprecated
  const [resultSets, setResultSets] = React.useState<Record<string, any>>({});
  const [columns, setColumns] = React.useState<Record<string, any>>({});

  function updateChartState({
    chartKey,
    modelAlias,
    queryOption,
    jsonQuery,
    renderedQuery,
    meta,
    elapsed,
    dataSource,
  }: UpdateChartStateProps) {
    if (jsonQuery) {
      setQueries((prev) => ({
        ...prev,
        [chartKey]: jsonQuery,
      }));
    }

    if (modelAlias) {
      setModelAliases((prev) => ({
        ...prev,
        [chartKey]: modelAlias,
      }));
    }

    if (queryOption) {
      setQueryOptions((prev) => ({
        ...prev,
        [chartKey]: queryOption,
      }));
    }

    if (renderedQuery) {
      setRenderedQueries((prev) => ({
        ...prev,
        [chartKey]: renderedQuery,
      }));
    }

    if (dataSource) {
      setDataSources((prev) => ({
        ...prev,
        [chartKey]: dataSource,
      }));
    }

    if (meta) {
      setJobsMeta((prev) => ({
        ...prev,
        [chartKey]: meta,
      }));
    }

    if (elapsed) {
      setJobsElapsed((prev) => ({
        ...prev,
        [chartKey]: elapsed,
      }));
    }
  }

  const values = {
    queries,
    setQueries,
    modelAliases,
    setModelAliases,
    queryOptions,
    setQueryOptions,
    renderedQueries,
    setRenderedQueries,
    jobsElapsed,
    setJobsElapsed,
    jobsMeta,
    setJobsMeta,
    resultSets,
    setResultSets,
    dataSources,
    columns,
    setColumns,
    setDataSources,
    updateChartState,
  };
  return <ChartWrapperContext.Provider value={values}>{children}</ChartWrapperContext.Provider>;
};

export const useChartWrapperContext = () => useContext(ChartWrapperContext);
