import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";

type UseGetGeneralField = {
  form?: FormInstance;
};
export const useGetGeneralField = (props: UseGetGeneralField) => {
  const { chartForm } = useABContext();
  const _form = props?.form ?? chartForm;
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, _form) ?? "{}";
  const parsedFiltersFieldWatch = JSON.parse(filtersFieldWatch);
  return getValueOfFiltersForm(parsedFiltersFieldWatch);
};
