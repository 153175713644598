import { Descriptions, Space, Table, Tooltip } from "antd";
import React from "react";
import { IMetricBatchEvaluationResult } from "@src/types/ab-testing-exploration";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { ABExplorationKeyWords } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { getMetricEvaluationRequireMeasures } from "@src/util/ab-testing/report-data/get-metric-evaluation-require-measures";
import { toTitle } from "@src/helpers/text-helper";
import { css } from "@emotion/react";
import { Typography } from 'antd';
import { 
  RiseOutlined, 
  FallOutlined } from "@ant-design/icons";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ControlReport, VariantReport } from "./variant-report";

const { Text } = Typography;


function getObjectMergedKey(r: any, keys?: string[]) {
  return keys?.map((k) => r[k]).join("-") || ""
}

export const ReportSummary: React.FC<{ 
    targetKey: string;
    chartKey: string;
    loading?: boolean,
    customTableProps?: any;
}> = ({ targetKey, chartKey, loading=false, customTableProps }) => {
  const { dataSources: {[chartKey]: rawData} } = useChartWrapperContext();
  const { form } = useMetricPerformanceContext();
  const { variants } = useGetGeneralField({ form });
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? {};

  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? form.getFieldValue(targetKey)?.[ChartFormFieldsEnum.METRIC_APPLIED] ?? {}
  const {
    [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy,
    [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: significanceLevel,
  } = metricApplied;

  const mergedDetailReport = React.useMemo(() => {
    if (rawData === undefined) return []
    const groupedData = Object.groupBy(rawData, (i) => getObjectMergedKey(i, breakdownBy))
    
    return Object.keys(groupedData).map((batchId) => {
      const records: any[] = groupedData[batchId] || []
      return {
        batchId,
        batchIdentifiers: breakdownBy?.map((k: any) => {
          if (records === undefined || records.length === 0) return {}

          return {
            [k]: records[0][k]
          }
        }).reduce((prev: any, curr: any) => Object.assign(prev, curr), {}) || {},
        // variants: records
        variants: records.map((rec) => {
          return {
            [rec[ABExplorationKeyWords.VARIANT]] : {
              ...rec,
            }
          }
        }).reduce((prev, curr) => Object.assign(prev, curr))
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rawData)])
  const detailTableColumns = []

  if (breakdownBy !== undefined && breakdownBy.length > 0) {
    detailTableColumns.push({
      title: "",
      children: [
        ...(
          breakdownBy.map((v: string) => {
            return {
              title: toTitle(v),
              dataIndex: ["batchIdentifiers", v],
              render: (value: any[]) => {
                return <div className="pl-[8px]">{value}</div>
              },
              filters: mergedDetailReport.map(({batchIdentifiers}) => {
                const {[v]: distinctValue} = batchIdentifiers
                return {
                  text: distinctValue,
                  value: distinctValue,
                }
              }),
              onFilter: (value, record) => record["batchIdentifiers"][v].indexOf(value as string) === 0,
              sorter: (a, b) => {
                const v1 = a["batchIdentifiers"][v].toString()
                const v2 = b["batchIdentifiers"][v].toString()
                return v1.localeCompare(v2)
              },

            }
          })
        )
      ]
    })
  }

  detailTableColumns.push({
    title: "Variants",
    // dataIndex: ["details"],
    children: [
      // ...(variants?.filter((v) => v !== baseVariant).map((v: string) => {
      ...(variants?.map((v: string) => {
        return {
          title: v,
          key: v,
          dataIndex: ["variants", v],
          render: (value: any, record) => {
            
            return (
              v === baseVariant ? 
              <ControlReport groupName={v} report={value}/> : 
              <VariantReport 
                groupName={v} 
                report={value} 
                isSignificantFn={(pValue) => {
                  return pValue < significanceLevel
                }}
                colorFn={(pValue, upliftPercent) => {
                  const color = {
                    bg: "bg-neutral-100",
                    upliftPercentTextColor: "text-slate-900", 
                  }
                  if (pValue === undefined || upliftPercent === undefined) {
                    return color
                  }

                  if (pValue < significanceLevel) {
                    color.bg = upliftPercent > 0 ? "bg-green-100" : "bg-red-100"
                    color.upliftPercentTextColor = upliftPercent > 0 ? "text-green-500" : "text-red-500"
                  }
                  return color
                }}
              />
            )
          }
        }
      }) || [])
    ]
  })

  return (
    <Table
        rowKey="batchId"
        sticky={{
            offsetHeader: 0,
        }}
        columns={detailTableColumns}
        dataSource={mergedDetailReport}
        bordered
        size="middle"
        pagination={false}
        css={css`
            /* .ant-table-tbody{
                padding: 0 !important;
            } */
            .ant-table-tbody > tr > td{
                padding: 0 !important;
            }

        `}
        loading={loading}
        {...customTableProps}
        
    />
  )
  
};
