import React from "react";
import { IMeasureType, IMetricBatchEvaluationResult, IQuery, IQueryFilter } from "@src/types/ab-testing-exploration";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import dayjs from "dayjs";
import { config } from "@src/config";
import { ABExplorationKeyWords, FilterOperator, SupportedOrderDirection } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { MIN_VARIANT_SAMPLE_SIZE } from "@src/constant/ab-testing";
import { getMetricEvaluationRequireMeasures } from "@src/util/ab-testing/report-data/get-metric-evaluation-require-measures";
import { useAbExplorationEvaluateMetric } from "@src/hooks/ab-testing/use-ab-exploration-evaluate-metric";
import { ChartLayoutWithUserPercent } from "@src/pages/ab-test-explore/component/layout/chart-layout-with-user-percent";
import { ReportSummary } from "./report-summary";
import { ReportRaw } from "./report-raw";
import { ChartAction, ChartActionLayout } from "../../../../action";
import { toTitle } from "@src/helpers/text-helper";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";


function getObjectMergedKey(r: any, keys: string[]) {
  return keys?.map((k) => r[k]).join("-") || "-"
}

export const ReportData: React.FC<{ targetKey: string; chartKey: string }> = ({ targetKey, chartKey }) => {
  const { form } = useMetricPerformanceContext();
  const { productCode, variants, maxDayDiff, explorationOptions: queryOptions, explorationFilters } = useGetGeneralField({ form });
  const actDateRange = Form.useWatch(ChartFormFieldsEnum.METRIC_TIME_RANGE, form) ?? [];
  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? {};
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? {};

  const {
    [ChartFormFieldsEnum.METRIC_TARGET_MODEL]: targetModelAlias,
    [ChartFormFieldsEnum.METRIC_MEASURE]: measureName,
    [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy,
    [ChartFormFieldsEnum.METRIC_FILTER]: filters,
    [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: significanceLevel,
    [ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]: measureOptions,
  } = metricApplied;

  const isMetricConfigReady = Object.keys(metricApplied).length > 0

  const baseJsonQuery = {
    productCode,
    modelAlias: targetModelAlias,
    options: queryOptions,
  }

  //start: get breakdown data
  const jsonQueryFilter: IQueryFilter[] =
    filters?.rules.map(({ field, operator, value }: { field: string; operator: string; value: string | string[] }) => {
      return {
        member: field,
        operator: operator === "=" ? "equals" : operator,
        values: typeof value === "string" ? [value] : value,
      };
    }) ?? [];

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.DAY_DIFF,
    operator: FilterOperator.BETWEEN,
    values: [0, maxDayDiff],
  });

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.ACT_DATE,
    operator: FilterOperator.BETWEEN,
    values: actDateRange.map((item: string) => dayjs(item).format(config.DATE_FORMAT)),
  });

  jsonQueryFilter.push(...explorationFilters);

  const shouldLoadOverview =
    actDateRange !== undefined &&
    targetModelAlias !== undefined &&
    measureName !== undefined 
    // && breakdownBy !== undefined && breakdownBy.length > 0;

  
  const overviewQueryRequest = React.useMemo(() => {
    const overviewJsonQuery: IQuery = {
      dimensions: new Array<string>(...new Set<string>([...(breakdownBy || [])])),
      measures: [ABExplorationKeyWords.SAMPLE_SIZE],
      filters: jsonQueryFilter,
      limit: 10000,
      order: [[ABExplorationKeyWords.SAMPLE_SIZE, SupportedOrderDirection.DESCENDANT]]
    }
    return {
      ...baseJsonQuery,
      query: overviewJsonQuery,
      isSkip: !isMetricConfigReady || !shouldLoadOverview,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(jsonQueryFilter), JSON.stringify(breakdownBy), JSON.stringify(measureName)])

  
  const { isLoading: isLoadingOverview, result: overviewResult} = useLoadAbExploration(overviewQueryRequest);

  // end: get breakdown data

  // start: get valid breakdown record
  // sample size must be >= MIN_VARIANT_SAMPLE_SIZE * variants
  const [validBreakdownRecords, inValidBreakdownRecords] = React.useMemo(()=> {
    if (overviewResult !== undefined && variants != undefined) {
      // return extractValidRecords(overviewResult, variants?.length)
      // return overviewResult.filter(({[ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize}) => sampleSize >= variants?.length * MIN_VARIANT_SAMPLE_SIZE)
      return [
        overviewResult.filter(({[ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize}) => sampleSize >= variants?.length * MIN_VARIANT_SAMPLE_SIZE),
        overviewResult.filter(({[ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize}) => sampleSize < variants?.length * MIN_VARIANT_SAMPLE_SIZE)
      ]
    }
    
    return [[],[]]
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(overviewResult), JSON.stringify(variants)])
  // end: get valid breakdown record

  // start: get valid breakdown filters
  // sample size must be >= MIN_VARIANT_SAMPLE_SIZE * variants
  const validBreakdownFilters = React.useMemo(()=> {
    if (breakdownBy !== undefined) {
      return breakdownBy.map((breakdown: string) => {
        const breakdownValues = Object.keys(
          Object.groupBy(validBreakdownRecords, (r) => r[breakdown])
        )
        return {
          member: breakdown,
          operator: FilterOperator.EQUALS,
          values: breakdownValues
        }
      })
    }
    return []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(overviewResult), JSON.stringify(variants)])
  // end: get valid breakdown record

  const { measure_type: measureType, measure_definition: measureDefinition } = JSON.parse(measureOptions ?? "{}");

  const { requiredMeasures, toEvaluatingData } = getMetricEvaluationRequireMeasures(
    measureName,
    measureType,
    measureDefinition,
    true,
    true,
  );

  const detailQueryRequest = React.useMemo(() => {
    
    const detailJsonQuery: IQuery = {
      dimensions: new Array<string>(
        ...new Set<string>([
          ...(breakdownBy || []),
          ABExplorationKeyWords.VARIANT
        ])
      ),
      // measures: [ABExplorationKeyWords.SAMPLE_SIZE],
      measures: requiredMeasures,
      filters: [...jsonQueryFilter],
      limit: 10000,
      order: [[ABExplorationKeyWords.SAMPLE_SIZE, SupportedOrderDirection.DESCENDANT]]
    }
    return {
      ...baseJsonQuery,
      query: detailJsonQuery,
      isSkip: !isMetricConfigReady || isLoadingOverview
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(overviewResult), JSON.stringify(requiredMeasures)])

  const { isLoading: isLoadingDetail, result: detailResult} = useLoadAbExploration(detailQueryRequest);

  const groupedDetailData = React.useMemo(() => {
    if (detailResult === undefined) {
      return {}
    }
    return Object.groupBy(detailResult, (i) => getObjectMergedKey(i, breakdownBy))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(detailResult)])

  const evaluatingMetricRequest = React.useMemo(
    () => {
      const batchData = Object.keys(groupedDetailData).filter((batchId: string) => {
        // only evaluate valid records
        return validBreakdownRecords.findIndex((rec) => getObjectMergedKey(rec, breakdownBy) === batchId) !== -1
      }).map((batchId: string) => {
        return {
          batch_id: batchId,
          data: groupedDetailData[batchId]?.map(({ [ABExplorationKeyWords.VARIANT]: variantName, ...rest }) => {
            return toEvaluatingData(variantName, rest);
          })
        }
      })

      return {
        baseGroup: baseVariant as string,
        variantGroups: variants || [],
        metric: measureName,
        metricType: measureType as IMeasureType,
        significanceLevel: significanceLevel as number,
        data: batchData,
        batch: true,
        isSkip: Object.keys(groupedDetailData).length === 0 || isLoadingDetail,
      } 
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(groupedDetailData)]
  );
  const { isLoading: isEvaluatingMetric, result: metricEvaluationResult } = useAbExplorationEvaluateMetric({...evaluatingMetricRequest});


  const { updateChartState } = useChartWrapperContext();

  const mergedDetailReport = React.useMemo(() => {
    if (metricEvaluationResult === undefined || detailResult === undefined) return []
    const groupedDetailData = Object.groupBy(detailResult, (i) => getObjectMergedKey(i, breakdownBy))
    return Object.keys(groupedDetailData).map((batchId) => {
      const metricEvaluationReport = (
        metricEvaluationResult.find((rec) => (rec as IMetricBatchEvaluationResult).batch_id === batchId) as IMetricBatchEvaluationResult
      )
      return groupedDetailData[batchId]?.map((rec) => {
        const metricEvaluation = metricEvaluationReport?.data.find(({variant_group}) => variant_group === rec[ABExplorationKeyWords.VARIANT]) || {}
        return {
          ...rec,
          ...metricEvaluation
        }
      }) || []
    }).reduce((prev, curr) => [...prev, ...curr], [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(metricEvaluationResult)])

  React.useEffect(() => {
    if (mergedDetailReport === undefined) return;
    updateChartState({
      chartKey,
      dataSource: mergedDetailReport,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mergedDetailReport)]);

  return (
    <>
    {
      Object.keys(metricApplied).length === 0 ? (
        <></>
      ) : (
        <ChartLayoutWithUserPercent
          defaultMode="chart"
          moreActions={
            <ChartActionLayout>
              <ChartAction tabKey={chartKey} tabName={toTitle(chartKey)} form={form} />
            </ChartActionLayout>
          }
          renderRaw={
            
            <ReportRaw 
              chartKey={chartKey} 
            />
          }
          renderChart={
              <ReportSummary 
                targetKey={targetKey} 
                chartKey={chartKey} 
              />
          }
          loading={isLoadingOverview || isLoadingDetail || isEvaluatingMetric}
        />
      )
    }
    </>
  )
  
};
