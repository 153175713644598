/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import React, { useState } from "react";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface SubmitButtonProps {
  form: FormInstance<any>;
}
export const SubmitButton: React.FC<SubmitButtonProps> = ({ form }) => {
  const paramsConfig = getGroupFieldValue("filterForm");
  const navigate = useNavigate();
  const { updateSearchUrl } = useABContext();

  const [currentFields, setCurrentFields] = useState<any>({});

  const productWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const productToken = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const platformWatch = Form.useWatch(FilterFormFieldsEnum.PLATFORM, form);
  const cohortWatch = Form.useWatch(FilterFormFieldsEnum.COHORT_DATE, form);
  const maxDayDiffWatch = Form.useWatch(FilterFormFieldsEnum.MAX_DAY_DIFF, form);

  const isAbaLoading = Form.useWatch(FilterFormFieldsEnum.IS_ABA_LOADING, form);
  const isAmaAppLoading = Form.useWatch(FilterFormFieldsEnum.IS_AMA_APP_LOADING, form);
  const isExpLoading = Form.useWatch(FilterFormFieldsEnum.IS_EXP_LOADING, form);

  const isRequireFieldLoading = isAbaLoading || isAmaAppLoading || isExpLoading;

  const extraFilterConfirmedWatch = Form.useWatch(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, form);

  const isHasPlatform = Array.isArray(platformWatch) && platformWatch?.length > 0;
  const isValidMaxDayDiff = typeof maxDayDiffWatch === "number" && maxDayDiffWatch !== undefined;
  const isUserAppliedFilter = Form.useWatch(FilterFormFieldsEnum.IS_FIRST_APPLIED, form);

  const enoughFieldsRequired =
    !!productWatch && !!productToken && abaIdWatch && isHasPlatform && !!cohortWatch && isValidMaxDayDiff;

  const mergeField = {
    [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
    [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
    [FilterFormFieldsEnum.VARIANTS]: variantWatch,
    [FilterFormFieldsEnum.PLATFORM]: platformWatch,
    [FilterFormFieldsEnum.COHORT_DATE]: cohortWatch,
    [FilterFormFieldsEnum.MAX_DAY_DIFF]: maxDayDiffWatch,
    [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFilterConfirmedWatch,
  };
  const isFieldChange = JSON.stringify(currentFields) !== JSON.stringify(mergeField);

  const isDisable = isRequireFieldLoading || !enoughFieldsRequired || !isFieldChange;

  const getInitValuesChart = () => {
    const chartForm = getGroupFieldValue("chartForm") ?? {};
    return {
      ...chartForm,
      [ChartFormFieldsEnum.CUSTOM_TIME_RANGE]: chartForm?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) =>
        dayjs(item),
      ),
    };
  };

  const handleClick = () => {
    form.validateFields().then(() => {
      updateSearchUrl(
        !isUserAppliedFilter
          ? {
              chartForm: getInitValuesChart(),
            }
          : undefined,
        navigate,
      );
      form.setFieldValue(FilterFormFieldsEnum.IS_FIRST_APPLIED, true);
      form.submit();
      setCurrentFields(mergeField);
    });
  };
  const [triggerApplyFirst, setTriggerApplyFirst] = useState(!!paramsConfig);

  React.useEffect(() => {
    if (isRequireFieldLoading) return;
    if (isDisable) return;
    if (isUserAppliedFilter) return;
    if (!triggerApplyFirst) return;
    console.log("auto submit");
    handleClick();
    setTriggerApplyFirst(false);
  }, [form, isDisable, isUserAppliedFilter, triggerApplyFirst, isRequireFieldLoading]);

  return (
    <div className="pt-7 mb-2">
      <Form.Item className="ml-2">
        <Button type="primary" loading={isRequireFieldLoading} disabled={isDisable} onClick={handleClick}>
          Apply
        </Button>
      </Form.Item>
    </div>
  );
};
