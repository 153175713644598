import { ABExplorationKeyWords, SupportedMeasureType } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useAbExplorationEvaluateMetric } from "@src/hooks/ab-testing/use-ab-exploration-evaluate-metric";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartLayoutWithUserPercent } from "@src/pages/ab-test-explore/component/layout/chart-layout-with-user-percent";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { IMeasureType, IQuery, IQueryFilter, IQueryOptions } from "@src/types/ab-testing-exploration";
import { getMetricEvaluationRequireMeasures } from "@src/util/ab-testing/report-data/get-metric-evaluation-require-measures";
import { Form, Spin, Table } from "antd";
import { useMemo } from "react";

type SimpleReportDataProps = {
  targetKey: string;
  jsonQueryFilter: IQueryFilter[];
  queryOptions: IQueryOptions;
};
export const SimpleReport: React.FC<SimpleReportDataProps> = ({ targetKey, jsonQueryFilter, queryOptions }) => {
  const { form } = useMetricPerformanceContext();
  const { productCode, variants } = useGetGeneralField({ form });
  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? {};
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? {};
  const {
    [ChartFormFieldsEnum.METRIC_TARGET_MODEL]: targetModelAlias,
    [ChartFormFieldsEnum.METRIC_MEASURE]: measureName,
    [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: significanceLevel,
    [ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]: measureOptions,
  } = metricApplied;

  const { measure_type: measureType, measure_definition: measureDefinition } = JSON.parse(measureOptions ?? "{}");

  const { requiredMeasures, toEvaluatingData } = getMetricEvaluationRequireMeasures(
    measureName,
    measureType,
    measureDefinition,
    true,
    true,
  );

  const dimensions = [ABExplorationKeyWords.VARIANT];

  const jsonQuery: IQuery = {
    dimensions,
    measures: requiredMeasures,
    filters: jsonQueryFilter,
    limit: 10000,
    order: [[ABExplorationKeyWords.VARIANT, "asc"]],
  };

  const { isLoading: isDataLoading, result } = useLoadAbExploration({
    productCode,
    modelAlias: targetModelAlias,
    query: jsonQuery,
    options: queryOptions,
  });

  const evaluatingData = useMemo(
    () => {
      return {
        baseGroup: baseVariant as string,
        variantGroups: variants || [],
        metric: measureName,
        metricType: measureType as IMeasureType,
        significanceLevel: significanceLevel as number,
        data: result?.map(({ [ABExplorationKeyWords.VARIANT]: variantName, ...rest }) => {
          return toEvaluatingData(variantName, rest);
        }),
        isSkip: !result,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(result)],
  );

  const { isLoading: isEvaluatingMetric, result: metricEvaluationResult } = useAbExplorationEvaluateMetric({
    ...evaluatingData,
  });

  const columns = [
    {
      key: ABExplorationKeyWords.VARIANT,
      dataIndex: "exp_group",
      title: "Variant",
    },
    {
      key: ABExplorationKeyWords.SAMPLE_SIZE,
      dataIndex: "user_count",
      title: "Sample Size",
    },
    {
      key:
        measureType === undefined || measureType === SupportedMeasureType.MEAN
          ? `${measureName}_per_user`
          : measureName,
      dataIndex:
        measureType === undefined || measureType === SupportedMeasureType.MEAN
          ? `${measureName}_per_user`
          : measureName,
      title: "Mean",
    },
    {
      key: "%change",
      title: "%",
      render: (e: any) => {
        if (isEvaluatingMetric) {
          return <Spin />;
        }
        const _variantName = e[ABExplorationKeyWords.VARIANT];
        const { base_group, uplift_percent, error_message } =
          metricEvaluationResult?.find(({ variant_group }) => variant_group === _variantName) || {};
        if (error_message) {
          return error_message;
        }
        return base_group === _variantName
          ? "-"
          : uplift_percent === undefined
          ? "Error"
          : // : (uplift_percent as number).toFixed(5);
            uplift_percent;
      },
    },
    {
      key: "p_value",
      title: "P-Value",
      render: (e: any) => {
        if (isEvaluatingMetric) {
          return <Spin />;
        }
        const _variantName = e[ABExplorationKeyWords.VARIANT];

        const { base_group, p_value, error_message } =
          metricEvaluationResult?.find(({ variant_group }) => variant_group === _variantName) || {};
        if (error_message) {
          return error_message;
        }
        // return base_group === _variantName ? "-" : p_value === undefined ? "Error" : (p_value as number).toFixed(5);
        return base_group === _variantName ? "-" : p_value === undefined ? "Error" : (p_value as number);
      },
    },
  ];

  // TODO: calculate p-value
  // TODO: compare result between groups
  // TODO: apply styling by comparison results

  return (
    <ChartLayoutWithUserPercent
      defaultMode="raw"
      hiddenMode
      renderRaw={
        <Table
          id="id"
          rowKey="id"
          sticky={{
            offsetHeader: 0,
          }}
          size="small"
          dataSource={result}
          loading={isDataLoading}
          columns={columns}
          pagination={false}
        />
      }
    />
  );
};
