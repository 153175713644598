import { DatabaseOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { USER_DIMENSIONS, useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { toTitle } from "@src/helpers/text-helper";

type BreakdownModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MetricBreakDown: React.FC<BreakdownModalProps> = ({ targetKey, open, setOpen }) => {
  const { form } = useMetricPerformanceContext();
  const [value, setValue] = React.useState<string | undefined>(
    form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]),
  );
  const targetedModel = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);
  const { getAvailableDimensions } = useAbTestExplorationContext();
  const availableDimensions = getAvailableDimensions({ modelAlias: targetedModel }) || [];
  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form);

  const handleOk = () => {
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], value);
    setOpen(false);
  };
  return (
    <div className="flex justify-center">
      <div
        className="flex items-center cursor-pointer hover:text-blue-600 text-gray-500 "
        onClick={() => {
          setOpen(true);
        }}
      >
        <DatabaseOutlined className="mr-2" />
        Breakdown ({metricBreakdownWatch?.length ?? 0})
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk} width={550} title="Metric breakdown" centered>
        <Select
          style={{ width: 500 }}
          mode="multiple"
          onChange={(value: string) => {
            setValue(value);
          }}
          maxCount={3}
          value={value}
          options={availableDimensions.filter(({matched_regex}) => matched_regex === undefined).map(({name, title}) => ({
            value: name,
            label: title || toTitle(name),
          }))}
          placeholder="Select breakdown"
        />
      </Modal>
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]} hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
