import React from "react";
import { useRawTableUserCount } from "./use-raw-table-user-count";
import { useLoadAbExploration } from "./use-ab-exploration-get-result";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useGetGeneralField } from "./use-get-general-field";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { useABContext } from "@src/contexts/ab-testing/ab-context";

type UseGroupingLoadAndGetRawDataProps = {
  chartKey: string;
  groupRawDataBy: {
    key: string;
    label: string;
  };
  jsonQuery: any;
  queryOptions: any;
  modelAlias: SupportedModelAlias;
};
export const useGroupingLoadAndGetRawData = ({
  chartKey,
  groupRawDataBy,
  jsonQuery,
  modelAlias,
  queryOptions,
}: UseGroupingLoadAndGetRawDataProps) => {
  const { chartForm } = useABContext();
  const { updateChartState } = useChartWrapperContext();
  const valuesOfGeneralField = useGetGeneralField({ form: chartForm });
  const { productCode } = valuesOfGeneralField;
  const { isSkip } = mappingQueryUserCount(valuesOfGeneralField, chartKey);

  const { result, meta, renderedQuery, isLoading, elapsed } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: jsonQuery,
    options: queryOptions,
  });

  const { tableProps } = useRawTableUserCount({
    loadResult: result ?? [],
    keyLabel: groupRawDataBy.label,
    groupBy: groupRawDataBy.key,
    chartKey,
    modelAlias,
  });
  React.useEffect(() => {
    if (isSkip) return;
    updateChartState({
      chartKey,
      modelAlias,
      queryOption: queryOptions,
      jsonQuery,
      renderedQuery,
      meta,
      elapsed,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedQuery, isSkip]);

  return {
    loadResult: result,
    isLoading,
    tableProps,
  };
};
