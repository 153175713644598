import React, { type CSSProperties } from "react";

import { useThemedLayoutContext } from "@refinedev/antd";
import { useGetIdentity, useLink, useMenu, useNavigation } from "@refinedev/core";

import { BarsOutlined, LeftOutlined, RightOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Drawer, Grid, Layout, Menu, theme } from "antd";
import { Title } from "./title";
import { whitelist } from "@src/mocks/email-whitelist";
import { MenuProps } from "antd/lib/menu";
import { TreeMenuItem } from "@refinedev/core/dist/hooks/menu/useMenu";

const drawerButtonStyles: CSSProperties = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  position: "fixed",
  top: 64,
  zIndex: 1001,
};

const { useToken } = theme;
type MenuItem = Required<MenuProps>["items"][number];

export const Sider: React.FC = () => {
  const { token } = useToken();
  const { siderCollapsed, setSiderCollapsed, mobileSiderOpen, setMobileSiderOpen } = useThemedLayoutContext();

  const { push } = useNavigation();
  const Link = useLink();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const { data } = useGetIdentity<any>();
  const shouldShowAdminPage = whitelist.includes(data?.email);

  const _menuItems = menuItems.filter((item) => {
    if (shouldShowAdminPage) return true;
    return item.name !== "Admin";
  });

  const menuItemsMapping = (items: TreeMenuItem[]): MenuItem[] => {
    return (
      items?.map((item) => {
        const baseItem = {
          key: item.key,
          label: <Link to={item.route ?? ""}>{item.label}</Link>,
          icon: item.icon ?? <UnorderedListOutlined />,
        };

        if (item.children?.length > 0) {
          return {
            ...baseItem,
            children: menuItemsMapping(item.children),
          };
        }

        return baseItem;
      }) ?? []
    );
  };

  React.useEffect(() => {
    if (selectedKey === "/") {
      push("/explorations");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  const renderMenu = () => {
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : []}
        defaultOpenKeys={!siderCollapsed ? defaultOpenKeys : ["/Exploration"]}
        mode="inline"
        style={{
          paddingTop: "8px",
          border: "none",
          overflow: "auto",
          height: "calc(100% - 200px)",
          background: "transparent",
        }}
        onClick={() => {
          setMobileSiderOpen(false);
        }}
        items={menuItemsMapping(_menuItems)}
      />
    );
  };

  React.useEffect(() => {
    setSiderCollapsed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isMobile) {
    return (
      <>
        <Drawer
          className="drawStyle"
          open={mobileSiderOpen}
          onClose={() => setMobileSiderOpen(false)}
          placement="left"
          closable={false}
          width={256}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              width={500}
              style={{
                height: "100vh",
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBorderBg}`,
              }}
            >
              <div
                style={{
                  width: "256px",
                  padding: "0 16px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "64px",
                  backgroundColor: token.colorBgElevated,
                  borderBottom: "none",
                }}
              >
                <Title collapsed={false} />
              </div>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
        <Button
          style={drawerButtonStyles}
          size="large"
          onClick={() => setMobileSiderOpen(true)}
          icon={<BarsOutlined />}
        />
      </>
    );
  }

  const siderStyles: React.CSSProperties = {
    backgroundColor: token.colorBgContainer,
    borderRight: `1px solid ${token.colorBorderBg}`,
    position: "sticky",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 999,
  };

  return (
    <Layout.Sider
      style={siderStyles}
      width={256}
      collapsible
      collapsed={siderCollapsed}
      onCollapse={(collapsed, type) => {
        if (type === "clickTrigger") {
          setSiderCollapsed(collapsed);
        }
      }}
      collapsedWidth={80}
      breakpoint="lg"
      trigger={
        <Button
          type="text"
          style={{
            borderRadius: 0,
            height: "100%",
            width: "100%",
            backgroundColor: token.colorBgElevated,
            borderRight: `1px solid ${token.colorBorderBg}`,
          }}
        >
          {siderCollapsed ? (
            <RightOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          ) : (
            <LeftOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          )}
        </Button>
      }
    >
      <div
        style={{
          width: siderCollapsed ? "80px" : "256px",
          padding: siderCollapsed ? "0" : "0 16px",
          display: "flex",
          justifyContent: siderCollapsed ? "center" : "flex-start",
          alignItems: "center",
          height: "64px",
          backgroundColor: token.colorBgElevated,
          fontSize: "14px",
        }}
      >
        <Title collapsed={siderCollapsed} />
      </div>
      {renderMenu()}
      {!siderCollapsed && <PlatformGuideline />}
    </Layout.Sider>
  );
};

const PlatformGuideline = () => {
  return (
    <div className="mx-2">
      <div
        className="relative flex min-w-0 flex-col items-center break-words rounded-2xl border-0 border-solid border-blue-900 bg-white bg-clip-border shadow-none after:absolute after:top-0 after:bottom-0 after:left-0 after:z-10 after:block after:h-full after:w-full after:rounded-2xl after:content-[''] "
        sidenav-card=""
        style={{
          background: "linear-gradient(180deg, rgba(233,235,248,1) 0%, rgba(217,217,217,1) 100%)",
        }}
      >
        <div className="relative z-20 flex-auto w-full p-4 text-left ">
          <div className="transition-all duration-200 ease-nav-brand">
            <div className="mb-0 text-[20px]">Need help?</div>
            <p className="mb-4 text-[14px] mt-2">Please check our docs</p>
            <Button
              className="w-full"
              href="https://docs.google.com/presentation/d/19BEBRU8HCsg1j8IoRVXPWWzICaiMB-uOFWljvcxY3Ko/edit#slide=id.p1"
              target="_blank"
              rel="noreferrer"
            >
              Platform Guideline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
