import { Table } from "antd";
import React from "react";
import { IMetricBatchEvaluationResult } from "@src/types/ab-testing-exploration";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { ABExplorationKeyWords } from "@src/constant/ab-testing/ad-testing-exploration.enum";
// import { sampleSize } from "lodash";


import type { TableProps } from 'antd';
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";

export const ReportRaw: React.FC<{ 
    chartKey: string;
    loading?: boolean,
    customTableProps?: any;
}> = ({ chartKey, loading=false, customTableProps }) => {
  const { dataSources: {[chartKey]: mergedDetailReport} } = useChartWrapperContext();

  let tableColumns: TableProps['columns'] = []

  if (mergedDetailReport !== undefined && mergedDetailReport.length > 0 ) {
    tableColumns = Object.keys(mergedDetailReport[0]).map((k, idx) => {
      return {
        key: idx,
        title: k,
        dataIndex: k,
        width: 150,
        filters: Object.entries(
          Object.groupBy(mergedDetailReport, (rec: any) => rec[k])
        ).map(([k, records]) => {
          return {
            text: `${k} (${records?.length || 0})`,
            value: k,
          }
        }),
      }
    })
  }

  return (
    <Table
        id="idx"
        rowKey="idx"
        sticky={{
            offsetHeader: 0,
        }}
        columns={tableColumns}
        dataSource={mergedDetailReport}
        // dataSource={[]}
        bordered
        size="middle"
        scroll={{ x: 'max-content'}}
        loading={loading}
        {...customTableProps}
        
    />
  )
  
};
