import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input, Select } from "antd";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { toTitle } from "@src/helpers/text-helper";
import { Typography } from 'antd';
import { SupportedMeasureType } from "@src/constant/ab-testing/ad-testing-exploration.enum";

const { Text } = Typography;

export const MetricMeasure: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form } = useMetricPerformanceContext();
  const { availableModels, isLoadingMeta, getAvailableMeasures, getAvailableDimensions } =
    useAbTestExplorationContext();

  const targetedModel = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);
  const availableMeasures = getAvailableMeasures({ modelAlias: targetedModel });

  React.useEffect(() => {
    const metricItems = form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS) ?? [];
    const targetItem = metricItems.find((item: any) => item.key === targetKey);
    const availableDimensions = getAvailableDimensions({ modelAlias: targetedModel }) ?? [];
    if (availableDimensions.length === 0) return;
    const requiredDimensions = availableDimensions.filter(({ options }) => {
      return Boolean(options?.filter?.required);
    });

    const currentMetricFilter = targetItem?.[ChartFormFieldsEnum.METRIC_FILTER] ?? {
      combinator: "and",
      rules: [],
    };

    const currentRules = Array.isArray(currentMetricFilter?.rules) ? currentMetricFilter?.rules : [];
    const newRules: any[] = [];

    currentRules.forEach((rule: any) => {
      if (availableDimensions?.some((dimension) => dimension?.name === rule?.field)) {
        newRules.push(rule);
        return;
      }
    });

    requiredDimensions?.forEach((dimension) => {
      if (newRules.some((rule: any) => rule.field === dimension?.name)) return;
      newRules.push({ field: dimension?.name, operator: "=", value: dimension?.name, id: uuidv4() });
    }, []);

    const newData = {
      ...currentMetricFilter,
      rules: newRules,
    };

    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], newData);
  }, [form, getAvailableDimensions, targetKey, targetedModel]);

  return (
    <>
      <Form.Item
        label="Model"
        name={[targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL]}
        required
        rules={[
          {
            message: "Please select a model",
            required: true,
          },
        ]}
      >
        <Select
          loading={isLoadingMeta}
          style={{ width: 200 }}
          onChange={() => {
            form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE], []);
          }}
          placeholder="Select a model"
        >
          {availableModels.map((alias) => {
            return (
              <Select.Option key={alias} value={alias}>
                {toTitle(alias)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Measure"
        name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE]}
        required
        rules={[
          {
            message: "Please select a measure",
            required: true,
          },
        ]}
      >
        <Select
          loading={isLoadingMeta}
          style={{ width: 400 }}
          disabled={!targetedModel}
          onChange={(_, opts: any) => {
            form.setFieldValue(
              [targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS],
              JSON.stringify(opts.record.options),
            );
          }}
          placeholder="Select a measure"
          options={
            targetedModel
            ? availableMeasures?.map((record: any) => ({ 
              label: <div className="flex justify-between">
                <div>{record?.title || toTitle(record.name)}</div>
                <div>
                  <Text type="secondary">{toTitle(record.options?.measure_type || SupportedMeasureType.MEAN)}</Text>
                </div>
              </div>, 
              value: record.name, record 
            }))
              : []
          }
        />
      </Form.Item>
      <Form.Item hidden name={[targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]}>
        <Input />
      </Form.Item>
    </>
  );
};
