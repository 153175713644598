import { FormInstance } from "antd/lib/form";
import { BaseVariant, MetricTimeRange } from "./component/general-fields";
import { MetricPerformanceTabs } from "./component/metric-performance-tabs";
import { MetricPerformanceContextProvider } from "@src/contexts/ab-testing/metric-performance-context";
import { AbTestExplorationContextProvider } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { MetricStoreTabItem } from "./component/store-visual/metric-store-tab-item";
import { css } from "@emotion/react";

export const MetricPerformance: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <div
      css={css`
        .ant-tabs-tab-remove {
          display: none;
        }
      `}
    >
      <div className="flex justify-between">
        <BaseVariant form={form} />
        <MetricTimeRange form={form} />
      </div>
      <MetricStoreTabItem form={form} />
      <AbTestExplorationContextProvider>
        <MetricPerformanceContextProvider form={form}>
          <MetricPerformanceTabs />
        </MetricPerformanceContextProvider>
      </AbTestExplorationContextProvider>
    </div>
  );
};
