import { RangePresetsDefault } from "@src/constant/default-range-picker-presets";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { COHORT_DATE } from "@src/pages/ab-test-explore/contanst";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { DatePicker, Form } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import React from "react";

const { RangePicker } = DatePicker;
const getDateRangeWithDayDiff = (dateRange: any[], dayDiff: number) => {
  const [start, end] = dateRange;
  return [dayjs(start), dayjs(end).add(dayDiff, "day")];
};
export const MetricTimeRange: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  const { installDate, onboardingDate, cohortDate, maxDayDiff } = useGetGeneralField({ form });

  React.useEffect(() => {
    if (!!installDate && cohortDate === COHORT_DATE.INSTALL_DATE) {
      form.setFieldValue(ChartFormFieldsEnum.METRIC_TIME_RANGE, getDateRangeWithDayDiff(installDate, maxDayDiff));
      setIsDisabled(true);
      return;
    }
    if (!!onboardingDate && cohortDate === COHORT_DATE.ONBOARDING_DATE) {
      form.setFieldValue(ChartFormFieldsEnum.METRIC_TIME_RANGE, getDateRangeWithDayDiff(onboardingDate, maxDayDiff));
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [installDate, onboardingDate, cohortDate, maxDayDiff, form]);

  const { updateSearchUrl } = useABContext();

  return (
    <Form.Item
      label="Time Range"
      name={ChartFormFieldsEnum.METRIC_TIME_RANGE}
      required
      rules={[
        {
          message: "Please select time range",
          required: true,
        },
      ]}
    >
      <RangePicker
        disabled={isDisabled}
        presets={RangePresetsDefault}
        style={{
          width: 500,
          backgroundColor: "white",
        }}
        onChange={() => {
          updateSearchUrl();
        }}
      />
    </Form.Item>
  );
};
