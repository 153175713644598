import { config } from "@src/config";
import {
  ALL_EXTRA_FIELDS_AS_DATE,
  ALL_EXTRA_FIELDS_AS_SELECT,
  ALL_EXTRA_FIELDS_FILTER,
  ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN,
} from "@src/pages/ab-test-explore/contanst/filter-fields";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { isArrayWithNotEmptyValues } from "@src/util/common/array";
import { convertDateRangeToString } from "@src/util/time-form";
import { Button } from "antd";
import { FormInstance } from "antd/lib/form";

export const ModalFooter: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const handleOk = () => {
    const values: Record<string, any> = {};
    ALL_EXTRA_FIELDS_AS_DATE.forEach((field) => {
      if (isArrayWithNotEmptyValues(form.getFieldValue(field))) {
        values[field] = convertDateRangeToString(form.getFieldValue(field));
      }
    });
    ALL_EXTRA_FIELDS_AS_SELECT.forEach((field) => {
      if (isArrayWithNotEmptyValues(form.getFieldValue(field))) {
        values[field] = form.getFieldValue(field);
      }
    });
    ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN.forEach((field) => {
      values[field] = form.getFieldValue(field) || undefined;
    });

    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });
    form.setFieldValue(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, JSON.stringify(values ?? {}));

    setTimeout(() => {
      form.setFieldsValue({
        [FilterFormFieldsEnum.IS_OPEN_MODAL]: false,
      });
    }, config.DEBOUNCE_TIME.SHORT_DELAY);
  };
  const handleClearAll = () => {
    ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
      form.setFieldValue(field, undefined);
    });
  };

  return (
    <div className="p-4 flex justify-end">
      <Button onClick={() => handleClearAll()}>Clear all filter</Button>
      <div className="w-2" />
      <Button type="primary" onClick={() => handleOk()}>
        Apply filters
      </Button>
    </div>
  );
};
