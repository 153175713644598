import { InputNumber, Space, Typography } from "antd";
import React from "react";
import { UserCountItemProps } from ".";
import { EChartWrapper } from "../../layout/echart-wrapper";
import ReactECharts from "echarts-for-react";
import { ChartAction, ChartActionLayout } from "../action";
import { RawDataTable } from "./raw-data-table";
import { ChartLayoutWithUserPercent } from "../../layout/chart-layout-with-user-percent";
import { useUserCountHeatmap } from "@src/hooks/ab-testing/use-user-count-heatmap";
import { toTitle } from "@src/helpers/text-helper";

const { Text } = Typography;

const UserCountByDeviceModal: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const {
    topValue,
    setTopValue,
    eChartKey,
    isEmpty,
    isLoading,
    maxTop,
    option,
    tableProps,
    chartDataType,
    setChartDataType,
  } = useUserCountHeatmap({
    form,
    dimension: "device_model",
    chartKey,
    chartName: toTitle(chartKey),
    tableLabel: toTitle(chartKey),
  });

  return (
    <ChartLayoutWithUserPercent
      chartDataType={chartDataType}
      setChartDataType={setChartDataType}
      loading={!!isLoading}
      moreActions={
        <ChartActionLayout>
          <ChartAction tabKey={chartKey} tabName={toTitle(chartKey)} form={form} />
        </ChartActionLayout>
      }
      renderRaw={<RawDataTable {...tableProps} />}
      renderChart={
        <EChartWrapper isEmpty={isEmpty}>
          <Space direction="horizontal">
            <Text>Top </Text>
            <InputNumber
              defaultValue={topValue}
              min={1}
              max={maxTop}
              onChange={(v) => {
                if (v) {
                  setTopValue(v);
                }
              }}
            />
            <Text>Device Model</Text>
          </Space>
          <ReactECharts key={eChartKey} option={option} style={{ height: "100%" }} />
        </EChartWrapper>
      }
    />
  );
};
export const UserCountByDeviceModalMemo = React.memo(UserCountByDeviceModal);
