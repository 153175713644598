import { ABExplorationKeyWords, FilterOperator } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { toTitle } from "@src/helpers/text-helper";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { useLoadChartMetricPerformance } from "@src/hooks/fb-explore/metric-performance/use-load-chart-metric-performance";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { IQueryFilter, IQueryOptions } from "@src/types/ab-testing-exploration";
import { Form, Table, Tooltip } from "antd";
import { ReportDetail } from "./report-detail";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import React from "react";
import { useDebounce } from "@src/hooks/use-debounce";

type NestedReportDataProps = {
  targetKey: string;
  chartKey: string;
  jsonQueryFilter: IQueryFilter[];
  queryOptions: IQueryOptions;
};
export const NestedReport: React.FC<NestedReportDataProps> = ({
  targetKey,
  chartKey,
  jsonQueryFilter,
  queryOptions,
}) => {
  const { result, meta, isLoading } = useLoadChartMetricPerformance({
    targetKey,
    chartKey,
  });
  const { form } = useMetricPerformanceContext();
  const { variants } = useGetGeneralField({ form });
  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? {};
  const metricAppliedDebounce = useDebounce(metricApplied, 300);
  const { [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy } = metricAppliedDebounce;

  const columns = React.useMemo(() => {
    return (
      meta?.schema?.map(({ name }) => {
        let normalizedTitle = name;
        switch (name) {
          case ABExplorationKeyWords.SAMPLE_SIZE: {
            normalizedTitle = "Sample Size";
            break;
          }
        }

        return {
          dataIndex: name,
          title: toTitle(normalizedTitle),

          filterSearch: true,
        };
      }) ?? []
    );
  }, [meta?.schema]);

  const mappingResult = React.useMemo(() => {
    const mappedRowKey = (record: Record<string, any>) => {
      return Object.keys(record)
        .filter((k) => {
          if (typeof breakdownBy === "string") {
            return breakdownBy === k;
          } else if (Array.isArray(breakdownBy)) {
            return breakdownBy.indexOf(k) > -1;
          }
          return false;
        })
        .map((k) => {
          return record[k];
        })
        .join("-");
    };
    return isLoading
      ? []
      : result?.map((record) => {
          return {
            ...record,
            rowKey: mappedRowKey(record),
          };
        }) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, result]);

  return (
    <Table
      rowKey={"rowKey"}
      sticky={{
        offsetHeader: 0,
      }}
      size="small"
      dataSource={mappingResult}
      loading={isLoading}
      columns={columns}
      expandable={{
        expandedRowRender: (record: any) => {
          const _jsonQueryFilter = [
            ...jsonQueryFilter,
            ...Object.keys(record)
              .filter((k) => {
                if (typeof breakdownBy === "string") {
                  return breakdownBy === k;
                }
                if (Array.isArray(breakdownBy)) {
                  return breakdownBy.indexOf(k) > -1;
                }
                return false;
              })
              .map((k) => {
                return {
                  member: k,
                  operator: FilterOperator.EQUALS,
                  values: [record[k]],
                };
              }),
          ];
          return <ReportDetail targetKey={targetKey} jsonQueryFilter={_jsonQueryFilter} queryOptions={queryOptions} />;
        },
        rowExpandable: (record) => record.user_count / (variants?.length || 1) > 300,
        expandIcon: ({ expanded, onExpand, record }) => {
          const shouldDisable = record.user_count / (variants?.length || 1) < 300;
          if (expanded) return <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />;
          return (
            <Tooltip title={shouldDisable ? "Each variant must be have more than 300 sample size" : ""}>
              {shouldDisable ? (
                <PlusCircleTwoTone twoToneColor={"#999999"} disabled={shouldDisable} />
              ) : (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              )}
            </Tooltip>
          );
        },
      }}
    />
  );
};
