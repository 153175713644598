export enum SupportedModelAlias {
  USER_INFO = "user-info",
  TICKET_INFO = "ticket-info",
  FIREBASE = "firebase",
  FIREBASE_ENGAGEMENT = "firebase-engagement",
  FIREBASE_PLAYTIME = "firebase-playtime",
  FIREBASE_ACTIVATION = "firebase-activation",
  AD_MONETIZATION = "ad-monetization",
  SUB_MONETIZATION = "sub-monetization",
  IAP_MONETIZATION = "iap-monetization",
}

export enum FilterOperator {
  EQUALS = "equals",
  BETWEEN = "between",
}

export enum SupportedMeasureType {
  MEAN = "mean",
  RATIO = "ratio",
  PROPORTION = "proportion",
}

export enum SupportedOrderDirection {
  ASCENDANT = "asc",
  DESCENDANT = "desc",
}

export enum ABExplorationKeyWords {
  SAMPLE_SIZE = "user_count",
  VARIANT = "exp_group",
  DAY_DIFF = "day_diff",
  ACT_DATE = "date_tzutc",
}
