import { PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Input, InputRef, Popover, Select, Space, Typography } from "antd";
import React from "react";
import { FieldSelectorProps } from "react-querybuilder";

export const CustomFieldSelector = (props: FieldSelectorProps) => {
  const { handleOnChange, rule } = props;
  const [selectedTemplateDimensions, setSelectedTemplateDimensions] = React.useState<any[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [templatedMember, setTemplatedMember] = React.useState<string>("");

  const { basicMembers, templateMembers } = props.options.reduce(
    (acc, _option: any) => {
      const { matched_regex } = _option ?? {};
      if (matched_regex === undefined) {
        acc.basicMembers.push(_option);
      } else {
        acc.templateMembers.push(_option);
      }
      return acc;
    },
    {
      basicMembers: [] as any[],
      templateMembers: [] as any[],
    },
  );
  const firstTemplate = templateMembers?.[0];
  const prefix = firstTemplate?.label?.split("_")?.[0];

  const matchedTemplates = templateMembers.map((mem) => {
    try {
      const matched = `${prefix + "_" + templatedMember}`.match(mem.matched_regex);
      return {
        ...mem,
        matched,
        matchedGroups: matched?.groups,
      };
    } catch (error) {
      console.log("error", error);
      return mem;
    }
  });

  const matchedAtMostOneTemplate = matchedTemplates.filter(({ matched }) => matched).length === 1;
  const validatedTemplatedMember = matchedTemplates[0] || undefined;

  const isTemplatedMemberDuplicated =
    basicMembers.filter(({ name }) => name === templatedMember).length > 0 ||
    selectedTemplateDimensions.filter(({ name }) => name === templatedMember).length > 0;

  const isTemplatedMemberValid =
    !isTemplatedMemberDuplicated && matchedAtMostOneTemplate && validatedTemplatedMember !== undefined;

  const inputRef = React.useRef<InputRef>(null);

  const submit = () => {
    const tmpDef = JSON.parse(JSON.stringify(validatedTemplatedMember));
    delete tmpDef["matched_regex"];
    delete tmpDef["matched"];
    delete tmpDef["matchedGroups"];

    const _templatedMember = `${prefix + "_" + templatedMember}`;
    setSelectedTemplateDimensions((prev) => {
      return [
        ...prev,
        {
          ...tmpDef,
          name: _templatedMember,
          label: _templatedMember,
        },
      ];
    });
    setTemplatedMember("");
    handleOnChange(_templatedMember);
    setIsOpen(false);
  };

  const blurTimeoutId = React.useRef<any>();

  const handleAddNewItem = () => {
    if (!isTemplatedMemberValid) {
      return;
    }
    submit();
  };
  const handleResetOnBlur = () => {
    if (blurTimeoutId.current) {
      clearTimeout(blurTimeoutId.current);
      blurTimeoutId.current = null;
    }
  };

  return (
    <Select
      open={isOpen}
      style={{ width: 350 }}
      onChange={(val) => {
        handleOnChange(val);
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      }}
      onBlur={() => {
        blurTimeoutId.current = setTimeout(() => {
          setIsOpen(false);
        }, 150);
      }}
      onClick={() => {
        setIsOpen(true);
        setTimeout(() => {
          inputRef.current?.focus();
          clearTimeout(blurTimeoutId.current);
        }, 100);
      }}
      value={rule.field}
      options={[...selectedTemplateDimensions, ...basicMembers].map(({ name }) => ({
        label: name,
        value: name,
      }))}
      dropdownRender={(menu) => (
        <div
          onClick={() => {
            handleResetOnBlur();
            setIsOpen(true);
          }}
        >
          {menu}
          {templateMembers?.length > 0 && (
            <>
              <Divider style={{ margin: "8px 0" }} />
              <Popover
                content={
                  <div>
                    {matchedTemplates.map((f) => {
                      const prefix = f?.label?.split("_")[0];

                      return (
                        <div className="max-w-[400px]">
                          <p className="m-0">
                            Add event param ({prefix}) to explore event with desired params. Input must match the
                            template:
                          </p>
                          <span className="text-gray-500 italic">Ex: {prefix}_difficulty_tag</span>
                        </div>
                      );
                    })}
                  </div>
                }
                title="Add event param"
                trigger="hover"
              >
                <Space
                  onClick={() => {
                    handleResetOnBlur();
                    setIsOpen(true);
                  }}
                  className="flex items-center justify-between"
                  style={{ padding: "0 8px 4px" }}
                >
                  <Input
                    prefix={
                      <Typography.Text>
                        <Badge
                          size="default"
                          status={matchedTemplates?.[0]?.matched ? "success" : "error"}
                          text={`${prefix}`}
                        />
                      </Typography.Text>
                    }
                    placeholder={"Event param"}
                    ref={inputRef}
                    value={templatedMember}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTemplatedMember(event.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        handleAddNewItem();
                      }
                    }}
                  />

                  <Button
                    style={{
                      backgroundColor: isTemplatedMemberValid ? "#4096ff" : "rgba(0, 0, 0, 0.04)",
                      color: isTemplatedMemberValid ? "#fff" : "rgba(0, 0, 0, 0.25)",
                    }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddNewItem}
                  >
                    Add item
                  </Button>
                </Space>
              </Popover>
            </>
          )}
        </div>
      )}
    />
  );
};
