import { Button, ConfigProvider, Descriptions, Space, Tag, Tooltip } from "antd";
import React from "react";
import { ABExplorationKeyWords } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { Typography } from 'antd';
import { 
  RiseOutlined, 
  FallOutlined } from "@ant-design/icons";
import { mean } from "lodash";

const { Text } = Typography;

const BaseReport: React.FC<{ 
  mean: number;
  pValue?: number;
  upliftPercent?: number;
  sampleSize?: number;
  colorFn?: (pValue?: number, upliftPercent?: number) => {
    bg: string,
    upliftPercentTextColor: string,
  }
}> = ({ 
  mean,
  pValue, 
  upliftPercent, 
  sampleSize, 
  colorFn,
}) => {

const {
  bg: bgColor,
  upliftPercentTextColor,
} = colorFn !== undefined ? colorFn(pValue, upliftPercent) : {
  bg: "bg-neutral-100",
  upliftPercentTextColor: "text-slate-900",
}

const uplift = (
  upliftPercent === undefined || upliftPercent === null ? "-" : (
    <div>
      <Text className={`${upliftPercentTextColor}`}>
        {
          upliftPercent > 0 ? 
          <RiseOutlined /> 
          : <FallOutlined/>
        }<> </>{Math.abs(upliftPercent * 100).toFixed(2)} %
      </Text>
    </div>
  )
)

const subValue = (
  <div className="text-[11px] text-slate-400">
    <Space>
      {mean !== undefined && mean !== null ? (mean as number).toFixed(5) : "-"}
      /
      {sampleSize !== undefined ? sampleSize : "-"}
    </Space>
  </div>
)

return <div className={`${bgColor} font-semibold flex flex-1 p-1`}>
  <div>
    {uplift}
    {subValue}
  </div>
</div>

};



export const VariantReport: React.FC<{ 
  groupName: string,
  report: any;
  isSignificantFn?: (pValue: number) => boolean;
  colorFn?: (pValue?: number, upliftPercent?: number) => {
    bg: string,
    upliftPercentTextColor: string,
  }
}> = ({ 
  // mean,
  groupName,
  report,
  isSignificantFn,
  colorFn,
}) => {
  const {
    method, 
    p_value, 
    mean_variant, 
    uplift_percent, 
    lower_bound_uplift_percent, 
    upper_bound_uplift_percent, 
    [ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize
  } = report || {}
  
  const isSignificant = isSignificantFn ? isSignificantFn(p_value) : false

  const tooltipContent = (
      <Descriptions 
        title={groupName} 
        extra={isSignificant ? <Tag className="text-[20px] font-light">Significant</Tag> : null}
      >
        <Descriptions.Item label="Method" span={3}>{method}</Descriptions.Item>
        <Descriptions.Item label="Mean" span={2}>{mean_variant}</Descriptions.Item>
        <Descriptions.Item label="Sample size" span={1}>{sampleSize}</Descriptions.Item>
        <Descriptions.Item label="% Change" span={3}>{uplift_percent ? `${(uplift_percent * 100 as number).toFixed(3)} %` : "-"}</Descriptions.Item>
        <Descriptions.Item label="Credible Interval" span={3}>
          <Space>
          {"["}
          {lower_bound_uplift_percent ? `${(lower_bound_uplift_percent * 100 as number).toFixed(3)} %` : "-"}
          {","}
          {upper_bound_uplift_percent ? `${(upper_bound_uplift_percent * 100 as number).toFixed(3)} %` : "-"}
          {"]"}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="P-Value" span={3}>
          {p_value ? (
            (p_value as number) > 0.001 ? (p_value as number).toFixed(3) : "< 0.001"
          ) : "-"}
        </Descriptions.Item>
      </Descriptions>
  )
  return (
    <Tooltip 
      title={method !== undefined ? tooltipContent : null} 
      overlayInnerStyle={{width: "500px", backgroundColor: "#fff"}}
      arrow={false}
      // getPopupContainer={triggerNode => triggerNode?.parentNode}
    >
      <div>
      <BaseReport 
        mean={mean_variant} 
        pValue={p_value} 
        upliftPercent={uplift_percent} 
        sampleSize={sampleSize} 
        colorFn={colorFn}
      />
      </div>
    </Tooltip>
  )
  
};


export const ControlReport: React.FC<{ 
  groupName: string;
  report: any;
}> = ({
  groupName, 
  report 
}) => {
  const {mean_base,  [ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize} = report || {}
  return <BaseReport mean={mean_base} sampleSize={sampleSize}/>
  
};
