import { useNavigation, useParsed } from "@refinedev/core";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "./enum";
import React from "react";

export const ABTestExploreRedirect: React.FC = () => {
  const { push } = useNavigation();
  const { params } = useParsed();

  React.useEffect(() => {
    try {
      const config = params?.config ?? "{}";
      const parsedConfig = JSON.parse(config);
      const parsedPlatform: Record<string, string> = {
        ios: "i",
        android: "a",
      };
      const _platform = parsedConfig?.platform?.map((item: string) => parsedPlatform[item]) ?? [];

      const filterForm: Record<string, any> = {
        [FilterFormFieldsEnum.PRODUCT_CODE]: parsedConfig?.product_code,
        [FilterFormFieldsEnum.COHORT_DATE]:
          parsedConfig?.cohort_type === "onboarding_date" ? "first_signal_date" : "install_date_tzutc",
        [FilterFormFieldsEnum.MAX_DAY_DIFF]: parsedConfig?.day_diff ?? 0,
        [FilterFormFieldsEnum.INVALID_COUNTRIES]: true,
        [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: true,
        [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: true,
        [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: true,
        [FilterFormFieldsEnum.ABA_ID]: Number(parsedConfig?.aba_id) ?? null,
        [FilterFormFieldsEnum.PLATFORM]: _platform,
        [FilterFormFieldsEnum.AMA_APP_IDS]: _platform?.map((p: string) => parsedConfig?.product_code + p),
        [FilterFormFieldsEnum.VARIANTS]: parsedConfig?.exp_group_id ?? [],
      };
      if (parsedConfig?.cohort_type === "onboarding_date") {
        filterForm[FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE] = parsedConfig?.date_time ?? [null, null];
      } else {
        filterForm[FilterFormFieldsEnum.EXTRA_INSTALL_DATE] = parsedConfig?.date_time ?? [null, null];
      }

      [
        {
          key: "country_code",
          name: FilterFormFieldsEnum.EXTRA_COUNTRY,
        },
        {
          key: "media_source",
          name: FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE,
        },
        {
          key: "device_category",
          name: FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY,
        },
        {
          key: "device_model",
          name: FilterFormFieldsEnum.EXTRA_DEVICE_MODAL,
        },
        {
          key: "first_app_version",
          name: FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION,
        },
        {
          key: "last_app_version",
          name: FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION,
        },
      ].forEach((item) => {
        if (parsedConfig[item.key]) {
          filterForm[item.name] = parsedConfig[item.key] ?? [];
        }
      });

      const convertConfig = {
        chartForm: {
          [ChartFormFieldsEnum.METRIC_BASE_VARIANTS]: parsedConfig?.base_variant,
        },
        filterForm: filterForm,
        tabsConfig: {},
      };
      push(`/ab-test-explorations?config=${btoa(JSON.stringify(convertConfig))}`);
    } catch (error) {
      console.log("error ", error);
      push("/ab-test-explorations");
    }
  }, [params?.config, push]);
  return null;
};
