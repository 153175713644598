import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { UserCountByInstallDateMemo } from "./user-count-by-install-date";
import { UserCountByMediaSourceMemo } from "./user-count-by-media-source";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import { UserDistributionTabsKeyEnum } from "@src/pages/ab-test-explore/enum";
import { UserCountByCountryCodeMemo } from "./user-count-by-country-code";
import { CubeProvider } from "@cubejs-client/react";
import { Tabs } from "antd";
import cubejs from "@cubejs-client/core";
import { config } from "@src/config";
import { MessageDescription } from "@src/constant/explore/message-description";
import { TooltipChartLayout } from "../../layout/tooltip-chart-layout";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { UserCountByDeviceCategoryMemo } from "./user-count-by-device-category";
import { UserCountByDeviceModalMemo } from "./user-count-by-device-modal";
import { UserCountByAgeMemo } from "./user-count-by-age";
import { UserCountByGenderMemo } from "./user-count-by-gender";
import { toTitle } from "@src/helpers/text-helper";
import { UserCountByOnboardingDateMemo } from "./user-count-by-onboarding-date";

export type UserCountItemProps = {
  form: FormInstance;
  chartKey: UserDistributionTabsKeyEnum;
};
const AbTestUserDistribution: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { productToken } = useGetGeneralField({ form });
  const CUBE_TOKEN = productToken ?? "";
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <ChartWrapperContextProvider>
        <Tabs
          items={[
            {
              key: UserDistributionTabsKeyEnum.INSTALL_DATE,
              label: (
                <TooltipChartLayout
                  label={toTitle(UserDistributionTabsKeyEnum.INSTALL_DATE)}
                  content={MessageDescription.USER_DISTRIBUTION.INSTALL_DATE}
                />
              ),
              children: <UserCountByInstallDateMemo form={form} chartKey={UserDistributionTabsKeyEnum.INSTALL_DATE} />,
            },
            {
              key: UserDistributionTabsKeyEnum.ONBOARDING_DATE,
              label: (
                <TooltipChartLayout
                  label={toTitle(UserDistributionTabsKeyEnum.ONBOARDING_DATE)}
                  content={MessageDescription.USER_DISTRIBUTION.ONBOARDING_DATE}
                />
              ),
              children: (
                <UserCountByOnboardingDateMemo form={form} chartKey={UserDistributionTabsKeyEnum.ONBOARDING_DATE} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.MEDIA_SOURCE,
              label: (
                <TooltipChartLayout
                  label={toTitle(UserDistributionTabsKeyEnum.MEDIA_SOURCE)}
                  content={MessageDescription.USER_DISTRIBUTION.MEDIA_SOURCE}
                />
              ),
              children: <UserCountByMediaSourceMemo form={form} chartKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} />,
            },
            {
              key: UserDistributionTabsKeyEnum.COUNTRY_CODE,
              label: (
                <div className="flex">
                  <TooltipChartLayout
                    label={toTitle(UserDistributionTabsKeyEnum.COUNTRY_CODE)}
                    content={MessageDescription.USER_DISTRIBUTION.COUNTRY}
                  />
                </div>
              ),
              children: <UserCountByCountryCodeMemo form={form} chartKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} />,
            },
            {
              key: UserDistributionTabsKeyEnum.AGE,
              label: (
                <div className="flex">
                  <TooltipChartLayout
                    label={toTitle(UserDistributionTabsKeyEnum.AGE)}
                    content={MessageDescription.USER_DISTRIBUTION.AGE}
                  />
                </div>
              ),
              children: <UserCountByAgeMemo form={form} chartKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} />,
            },
            {
              key: UserDistributionTabsKeyEnum.GENDER,
              label: (
                <div className="flex">
                  <TooltipChartLayout
                    label={toTitle(UserDistributionTabsKeyEnum.GENDER)}
                    content={MessageDescription.USER_DISTRIBUTION.GENDER}
                  />
                </div>
              ),
              children: <UserCountByGenderMemo form={form} chartKey={UserDistributionTabsKeyEnum.GENDER} />,
            },
            {
              key: UserDistributionTabsKeyEnum.DEVICE_CATEGORY,
              label: (
                <div className="flex">
                  <TooltipChartLayout
                    label={toTitle(UserDistributionTabsKeyEnum.DEVICE_CATEGORY)}
                    content={MessageDescription.USER_DISTRIBUTION.DEVICE_CATEGORY}
                  />
                </div>
              ),
              children: (
                <UserCountByDeviceCategoryMemo form={form} chartKey={UserDistributionTabsKeyEnum.DEVICE_CATEGORY} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.DEVICE_MODEL,
              label: (
                <div className="flex">
                  <TooltipChartLayout
                    label={toTitle(UserDistributionTabsKeyEnum.DEVICE_MODEL)}
                    content={MessageDescription.USER_DISTRIBUTION.DEVICE_MODEL}
                  />
                </div>
              ),
              children: <UserCountByDeviceModalMemo form={form} chartKey={UserDistributionTabsKeyEnum.DEVICE_MODEL} />,
            },
          ]}
        />
        {/* <Collapse
          className="ab-testing-collapse"
          activeKey={items}
          onChange={onChange}
          items={[
            {
              key: UserDistributionTabsKeyEnum.INSTALL_DATE,
              label: (
                <TooltipChartLayout label="Install Date" content={MessageDescription.USER_DISTRIBUTION.INSTALL_DATE} />
              ),
              children: <UserCountByInstallDateMemo form={form} chartKey={UserDistributionTabsKeyEnum.INSTALL_DATE} />,
              extra: (
                <ChartAction tabKey={UserDistributionTabsKeyEnum.INSTALL_DATE} tabName="Install Date" form={form} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.MEDIA_SOURCE,
              label: (
                <TooltipChartLayout label="Media Source" content={MessageDescription.USER_DISTRIBUTION.MEDIA_SOURCE} />
              ),
              children: <UserCountByMediaSourceMemo form={form} chartKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} />,
              extra: (
                <ChartAction tabKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} tabName="Media Source" form={form} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.COUNTRY_CODE,
              label: <TooltipChartLayout label="Country" content={MessageDescription.USER_DISTRIBUTION.COUNTRY} />,
              children: <UserCountByCountryCodeMemo form={form} chartKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} />,
              extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} tabName="Country" form={form} />,
            },
            // TODO: convert to data access api later
            // {
            //   key: UserDistributionTabsKeyEnum.GENDER,
            //   label: <TooltipChartLayout label="Gender" content={MessageDescription.USER_DISTRIBUTION.GENDER} />,
            //   children: <UserCountByGenderMemo form={form} chartKey={UserDistributionTabsKeyEnum.GENDER} />,
            //   extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.GENDER} tabName="Gender" form={form} />,
            // },
            // {
            //   key: UserDistributionTabsKeyEnum.AGE,
            //   label: <TooltipChartLayout label="Age" content={MessageDescription.USER_DISTRIBUTION.AGE} />,
            //   children: <UserCountByAgeMemo form={form} chartKey={UserDistributionTabsKeyEnum.AGE} />,
            //   extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.AGE} tabName="Age" form={form} />,
            // },
          ]}
        /> */}
      </ChartWrapperContextProvider>
    </CubeProvider>
  );
};

export const AbTestUserDistributionMemo = React.memo(AbTestUserDistribution);
