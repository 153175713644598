import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { COHORT_DATE, COHORT_DATE_LABEL } from "@src/pages/ab-test-explore/contanst";

export const CohortDateFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <Form.Item
      className="mx-1"
      label="Cohort Date"
      name={FilterFormFieldsEnum.COHORT_DATE}
      required
      rules={[
        {
          message: "Please select a cohort date",
          required: true,
        },
      ]}
    >
      <Select
        allowClear
        placeholder="Select Cohort Date"
        options={[
          {
            label: COHORT_DATE_LABEL.INSTALL_DATE,
            value: COHORT_DATE.INSTALL_DATE,
          },
          {
            label: COHORT_DATE_LABEL.ONBOARDING_DATE,
            value: COHORT_DATE.ONBOARDING_DATE,
          },
        ]}
        showSearch
        filterOption={(input, option) => {
          const { label, value } = option as any;
          if (!value || !label) return false;
          return (
            label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        style={{
          width: 200,
        }}
      />
    </Form.Item>
  );
};
