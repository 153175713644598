import { config } from "@src/config";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

export const MetricStoreTabItem: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();
  const metricTabItems = Form.useWatch(ChartFormFieldsEnum.METRIC_TAB_ITEMS, form);

  React.useEffect(() => {
    if (!metricTabItems) return;
    const timeoutId = setTimeout(() => {
      updateSearchUrl();
    }, config.DEBOUNCE_TIME.SHORT_DELAY);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [JSON.stringify(metricTabItems)]);

  return (
    <Form.Item name={ChartFormFieldsEnum.METRIC_TAB_ITEMS} hidden>
      <Input />
    </Form.Item>
  );
};
