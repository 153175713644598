import PivotGroup from "@src/components/query-builder/pivot-group";
import { useExploreContext } from "@src/contexts/explore-context";
import { Button, Card, Dropdown, Form, Tooltip } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { CopyOutlined, DownloadOutlined, UndoOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { TableResultQueryBuilder } from "./table-result";
import { useChartResult } from "@src/hooks/fb-explore/use-chart-result";
import { ChartResultWrapperComponent } from "./chart-result-wrapper-component";

type ChartResultProps = {
  mode: "create" | "edit";
  layout: any;
  pivotConfig: any;
  setPivotConfig?: React.Dispatch<any>;
  form?: FormInstance<any>;
};
export const ChartResult: React.FC<ChartResultProps> = ({ layout, pivotConfig, setPivotConfig, form }) => {
  const platformWatch = Form.useWatch("platform", form);
  const {
    pageSize,
    setPageSize,
    page,
    setPage,
    isLoading,
    contextHolder,
    isQueryChanging,
    messageApi,
    onRefresh,
    resultSet,
    refetchResult,
  } = useChartResult();
  const { product, error } = useExploreContext();

  const { isEditMode } = getModeOfLocation();

  const tablePivot = resultSet?.tablePivot() || [];
  function convertToCSV(arr: any[]) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it).reduce((acc, curr) => {
          acc += curr + "\t";
          return acc;
        }, "");
      })
      .join("\n");
  }

  function copyDataPivot(type: "JSON" | "CSV") {
    let dataCopy = "";
    if (type === "JSON") {
      dataCopy = JSON.stringify(tablePivot, null, 2);
    } else {
      dataCopy = convertToCSV(tablePivot);
    }
    navigator.clipboard.writeText(dataCopy);
    messageApi.open({
      type: "success",
      content: "Copy success!",
    });
  }
  const isEmptyPlatform = !platformWatch || platformWatch.length === 0;

  return (
    <ChartResultWrapperComponent>
      {contextHolder}
      <Card
        title={
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <span>Result</span>
              <PivotGroup layout={layout} setPivotConfig={setPivotConfig} />
            </div>
            <div>
              <Tooltip color="gold" title={`${!product ? "Please select product before refresh result!" : ""}`}>
                <Button
                  icon={<UndoOutlined />}
                  className={`m-2 ${isQueryChanging ? "bg-yellow-300" : ""}`}
                  onClick={() => {
                    if (form === undefined) {
                      onRefresh();
                      return;
                    }
                    form?.validateFields(["custom-time-range"], {}).then(() => {
                      onRefresh();
                    });
                  }}
                  disabled={Boolean(!product) || !!error || !isQueryChanging || isEmptyPlatform}
                >
                  {isEditMode ? "Refresh result" : "Preview result"}
                </Button>
              </Tooltip>
              {resultSet && (
                <>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <span
                              onClick={() => {
                                copyDataPivot("JSON");
                              }}
                            >
                              JSON
                            </span>
                          ),
                          key: "JSON",
                        },
                        {
                          label: (
                            <span
                              onClick={() => {
                                copyDataPivot("CSV");
                              }}
                            >
                              CSV
                            </span>
                          ),
                          key: "CSV",
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Tooltip title="Copy data">
                      <Button
                        type="text"
                        disabled={tablePivot.length >= 1000}
                        style={{ color: tablePivot.length >= 1000 ? "rgba(0, 0, 0, 0.25)" : "#1677ff" }}
                        icon={<CopyOutlined />}
                      />
                    </Tooltip>
                  </Dropdown>

                  <Tooltip title="Export CSV">
                    <CSVLink
                      filename={layout.name}
                      data={tablePivot}
                      headers={resultSet?.tableColumns().map((c: any) => {
                        return { label: c.title, key: c.key };
                      })}
                    >
                      <Button
                        type="text"
                        style={{ color: "#1677ff" }}
                        icon={<DownloadOutlined />}
                        onMouseDown={() => {
                          messageApi.open({
                            type: "success",
                            content: "Export CSV success!",
                          });
                        }}
                      />
                    </CSVLink>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        }
        size="small"
        className="h-full"
      >
        <TableResultQueryBuilder
          pivotConfig={pivotConfig}
          refetchResult={refetchResult}
          resultSet={resultSet}
          isLoading={isLoading}
          paginationControl={{
            pageSize,
            page,
            setPage,
            setPageSize,
          }}
        />
      </Card>
    </ChartResultWrapperComponent>
  );
};
