import React from "react";
import { createContext, useContext } from "react";

import { HttpError, useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { IMetaResponse, IModelAlias, IDimension, IMeasure } from "@src/types/ab-testing-exploration";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
type AbTestExplorationContextType = {
  isLoadingMeta: boolean;
  loadMetaError: HttpError | null;
  refreshMeta: () => void;
  availableModels: IModelAlias[];
  getAvailableDimensions: ({ modelAlias }: { modelAlias: IModelAlias }) => IDimension[] | undefined;
  getAvailableMeasures: ({ modelAlias }: { modelAlias: IModelAlias }) => IMeasure[] | undefined;
};
export const AbTestExplorationContext = createContext({} as AbTestExplorationContextType);

type IProps = {
  children: React.ReactNode;
};

// TODO: refactor API later
export const USER_DIMENSIONS: string[] = [
  "device_model",
  "device_category",
  "media_source",
  "country_code",
  "first_app_version",
  "last_app_version",
  "first_signal_date",
  "day0_date",
  "install_date_tzutc",
];
const MEMBER_WHITELIST: Record<string, any> = {
  [SupportedModelAlias.AD_MONETIZATION]: {
    dimensions: [...USER_DIMENSIONS, "ad_network", "ad_unit"],
    measures: ["ad_value", "ad_impressions", "ecpm"],
  },
  
  [SupportedModelAlias.SUB_MONETIZATION]: {
    dimensions: [...USER_DIMENSIONS, "package_id"],
    measures: ["trial_count", "trial_rate", "pay_count", "pay_rate", "total_subscription_value"],
  },

  [SupportedModelAlias.IAP_MONETIZATION]: {
    dimensions: [...USER_DIMENSIONS],
    measures: ["nosub_pay_rate", "iap_pay_count", "total_iap_value"],
  },

  [SupportedModelAlias.FIREBASE]: {
    dimensions: [
      "event_name",
      "ga_session_number",
      "event_name",
      "app_version",
      ...USER_DIMENSIONS,
      "ep_{param_key}",
    ],
    measures: [
      "event_count", 
    ],
  },
  
  [SupportedModelAlias.FIREBASE_ENGAGEMENT]: {
    dimensions: [
      "event_name",
      "ga_session_number",
      "event_name",
      "app_version",
      ...USER_DIMENSIONS,
    ],
    measures: [
      "sum_engagement_time", 
      "retention",
      "sum_retention",
      "sum_retention_exclude_day0",
    ],
  },

  [SupportedModelAlias.FIREBASE_PLAYTIME]: {
    dimensions: [
      "event_name",
      "ga_session_number",
      "event_name",
      "app_version",
      ...USER_DIMENSIONS,
    ],
    measures: [
      "sum_song_playtime", 
    ],
  },
  
  [SupportedModelAlias.FIREBASE_ACTIVATION]: {
    dimensions: USER_DIMENSIONS,
    measures: [
      "setup_rate", 
      "aha_rate", 
      "habit_rate", 
    ],
  },


};

export const AbTestExplorationContextProvider: React.FC<IProps> = ({ children }) => {
  const {
    data,
    error: loadMetaError,
    isLoading: isLoadingMeta,
    refetch: refreshMeta,
  } = useCustom<IMetaResponse, HttpError>({
    url: `${config.BACKEND_URL}/exploration_v2/ab-test-exploration/meta`,
    method: "get",
    queryOptions: {
      enabled: false,
    },
  });

  React.useEffect(() => {
    refreshMeta();
  }, []);

  const models = data?.data?.models ?? [];

  // TODO: consider to remove from api response
  const ableToExploreModels: IModelAlias[] = models
    .map(({ alias }) => alias)
    .filter((alias) => alias !== SupportedModelAlias.USER_INFO)
    .filter((alias) => MEMBER_WHITELIST[alias] !== undefined);

  function getAvailableMembers({
    modelAlias,
    memberType,
  }: {
    modelAlias: IModelAlias;
    memberType: "dimensions" | "measures";
  }): (IDimension | IMeasure)[] | undefined {
    const targetModel = models.find(({ alias }) => {
      return alias === modelAlias;
    });

    if (targetModel) {
      const whitelist: string[] = MEMBER_WHITELIST[modelAlias]?.[memberType] ?? [];
      return (
        targetModel[memberType]?.filter(({ name }) => {
          return whitelist.indexOf(name) !== -1;
        }) || []
      );
    }
  }

  function getAvailableDimensions({ modelAlias }: { modelAlias: IModelAlias }): IDimension[] | undefined {
    return getAvailableMembers({ modelAlias, memberType: "dimensions" });
  }

  function getAvailableMeasures({ modelAlias }: { modelAlias: IModelAlias }): IMeasure[] | undefined {
    return getAvailableMembers({ modelAlias, memberType: "measures" });
  }

  const values = {
    isLoadingMeta,
    loadMetaError,
    refreshMeta,
    availableModels: ableToExploreModels,
    getAvailableDimensions,
    getAvailableMeasures,
  };
  return <AbTestExplorationContext.Provider value={values}>{children}</AbTestExplorationContext.Provider>;
};

export const useAbTestExplorationContext = () => useContext(AbTestExplorationContext);
