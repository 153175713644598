import React from "react";
import { ChartDataTypeEnum, useChartLayout } from "./use-chart-layout";
import { useGetGeneralField } from "./use-get-general-field";
import { IQuery } from "@src/types/ab-testing-exploration";
import { useGroupingLoadAndGetRawData } from "./use-grouping-load-and-get-raw-data";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { formatNumber } from "@src/util/number-format";

type UseUserCountHeatmapProps = {
  dimension: string;
  form: any;
  chartKey: string;
  chartName: string;
  tableLabel: string;
};
export const useUserCountHeatmap = ({ form, dimension, chartKey, chartName, tableLabel }: UseUserCountHeatmapProps) => {
  const { chartDataType, setChartDataType } = useChartLayout();
  const generalFieldValues = useGetGeneralField({ form });
  const { explorationFilters, explorationOptions, getExpAliasById } = generalFieldValues;
  const [topValue, setTopValue] = React.useState(5);

  const jsonQuery: IQuery = {
    dimensions: ["exp_group", dimension],
    measures: ["user_count"],
    filters: explorationFilters,
    limit: 10000,
    order: [
      ["user_count", "desc"],
      ["exp_group", "asc"],
    ],
  };

  const modelAlias = SupportedModelAlias.USER_INFO;

  const {
    loadResult: result,
    tableProps,
    isLoading,
  } = useGroupingLoadAndGetRawData({
    chartKey: chartKey,
    jsonQuery: jsonQuery,
    modelAlias: modelAlias,
    queryOptions: explorationOptions,
    groupRawDataBy: {
      key: dimension,
      label: tableLabel,
    },
  });

  const groupResultByDimension = result ? Object.groupBy(result, (v) => v?.[dimension]) : {};

  const maxTop = Object.keys(groupResultByDimension).length;

  const totalRef = React.useRef<Record<string, number>>({});

  const maxVal = React.useMemo(() => {
    const groupResultByExpGroup = result ? Object.groupBy(result, ({ exp_group }) => exp_group) : {};
    let _maxValue = 0;

    Object.keys(groupResultByExpGroup).forEach((key) => {
      const totalUserCount =
        groupResultByExpGroup?.[key]?.reduce((acc: number, d: any) => acc + d["user_count"], 0) ?? 1;
      totalRef.current[key] = totalUserCount;
      const maxOfItem = Math.max(
        ...(groupResultByExpGroup[key]?.map(({ user_count }) => {
          if (chartDataType === ChartDataTypeEnum.USER_PERCENT) {
            return (user_count * 100) / totalUserCount;
          }
          return user_count;
        }) ?? [0]),
      );
      _maxValue = Math.max(_maxValue, maxOfItem);
    });

    return _maxValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataType, JSON.stringify(result), JSON.stringify(totalRef.current)]);

  const sourceDataSet = React.useMemo(() => {
    return (
      Object.keys(groupResultByDimension)
        ?.slice(0, topValue)
        ?.map((key) => {
          if (chartDataType === ChartDataTypeEnum.USER_PERCENT) {
            return (
              groupResultByDimension?.[key]?.map((d: any) => {
                const totalUserCount = totalRef.current[d["exp_group"]] ?? 0;
                return {
                  x: d[dimension],
                  y: getExpAliasById(d["exp_group"]),
                  value: totalUserCount === 0 ? 0 : ((d["user_count"] * 100) / totalUserCount).toFixed(2),
                };
              }) ?? []
            );
          }
          return groupResultByDimension[key]?.map((item: any) => {
            return {
              x: item[dimension],
              y: getExpAliasById(item["exp_group"]),
              value: item["user_count"],
            };
          });
        })
        ?.flat(1) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groupResultByDimension), topValue, chartDataType, JSON.stringify(totalRef.current)]);

  const option: echarts.EChartsOption = {
    tooltip: {
      position: "top",
    },
    grid: {
      height: "50%",
      top: "10%",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 30,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: maxVal,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    dataset: [
      {
        source: sourceDataSet,
      },
    ],
    series: [
      {
        name: chartName,
        type: "heatmap",
        label: {
          show: true,
          formatter: (params: any) => {
            return chartDataType === ChartDataTypeEnum.USER_COUNT ? params.value?.value : params.value?.value + "%";
          },
        },
        tooltip: {
          formatter: (param: any) => {
            let templateString = ``;
            templateString += `${param?.marker} ${param?.data?.x}: ${
              chartDataType === ChartDataTypeEnum.USER_COUNT
                ? formatNumber(param?.data?.value)
                : param?.data?.value + "%"
            } <br />`;
            return templateString;
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    gradientColor: ["#4bcffa", "#0fbcf9", "#6a89cc", "#4a69bd", "#1e3799"],
  };
  return {
    eChartKey: JSON.stringify(maxVal) + JSON.stringify(sourceDataSet),
    isEmpty: result === undefined || (result && result.length === 0),
    option,
    isLoading,
    tableProps,
    topValue,
    setTopValue,
    maxTop,
    maxVal,
    chartDataType,
    setChartDataType,
  };
};
