import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum, MetricBreakDownTabsChartKeyEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { IQuery, IQueryFilter, IQueryOptions } from "@src/types/ab-testing-exploration";
import dayjs from "dayjs";
import { config } from "@src/config";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import React from "react";
import { FilterOperator } from "@src/constant/ab-testing/ad-testing-exploration.enum";

export const useLoadChartMetricPerformance = ({ targetKey, chartKey }: { targetKey: string; chartKey: string }) => {
  const { updateChartState } = useChartWrapperContext();

  const { form } = useMetricPerformanceContext();
  const actDateRange = Form.useWatch(ChartFormFieldsEnum.METRIC_TIME_RANGE, form) ?? [];
  const { productCode, explorationFilters, cohortDate, maxDayDiff, explorationOptions } = useGetGeneralField({ form });
  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? {};
  const {
    [ChartFormFieldsEnum.METRIC_TARGET_MODEL]: targetModelAlias,
    [ChartFormFieldsEnum.METRIC_MEASURE]: measureName,
    [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy,
    [ChartFormFieldsEnum.METRIC_FILTER]: filters,
  } = metricApplied;

  const readyToLoad =
    actDateRange !== undefined &&
    cohortDate !== undefined &&
    maxDayDiff !== undefined &&
    targetModelAlias !== undefined &&
    measureName !== undefined;

  let dimensions;
  let measures;
  let order = undefined;

  switch (chartKey) {
    case MetricBreakDownTabsChartKeyEnum.USER_PERCENTILES: {
      dimensions = ["exp_group"];
      measures = [...Array(101).keys()].map((percent) => `p${percent}_${measureName}`);
      break;
    }
    case MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_DAY_DIFF: {
      dimensions = ["exp_group"];
      measures = [...Array(maxDayDiff + 1).keys()].map((dayDiff) => `${measureName}${dayDiff}`);
      break;
    }
    case MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_ACT_DATE: {
      dimensions = ["exp_group", "date_tzutc"];
      measures = [measureName];
      break;
    }
    case MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_COHORT_DATE: {
      dimensions = ["exp_group", "day0_date"];
      measures = [measureName];
      break;
    }
    case MetricBreakDownTabsChartKeyEnum.REPORT_DATA: {
      // dimensions = new Array<string>(...new Set<string>([...(breakdownBy || []), "exp_group"]));
      dimensions = new Array<string>(...new Set<string>([...(breakdownBy || [])]));
      measures = new Array<string>(...new Set<string>(["user_count", measureName]));
      order = [["user_count", "desc"]];
      break;
    }
    default:
      dimensions = ["exp_group"];
      measures = [...Array(101).keys()].map((percent) => `p${percent}_${measureName}`);
  }

  const jsonQueryFilter: IQueryFilter[] =
    filters?.rules.map(({ field, operator, value }: { field: string; operator: string; value: string | string[] }) => {
      return {
        member: field,
        operator: operator === "=" ? "equals" : operator,
        values: typeof value === "string" ? [value] : value,
      };
    }) ?? [];

  if (maxDayDiff) {
    jsonQueryFilter.push({
      member: "day_diff",
      operator: FilterOperator.BETWEEN,
      values: [0, maxDayDiff],
    });
  }
  if (actDateRange) {
    jsonQueryFilter.push({
      member: "date_tzutc",
      operator: FilterOperator.BETWEEN,
      values: actDateRange.map((item: string) => dayjs(item).format(config.DATE_FORMAT)),
    });
  }

  const jsonQuery: IQuery = {
    dimensions,
    measures,
    filters: [...explorationFilters, ...jsonQueryFilter],
    limit: 10000,
    order:
      order ||
      dimensions.map((b: string) => {
        return [b, "asc"];
      }),
  };

  const response = useLoadAbExploration({
    productCode,
    modelAlias: targetModelAlias,
    query: jsonQuery,
    options: explorationOptions,
    isSkip: !readyToLoad,
  });

  const { result, renderedQuery, meta, elapsed } = response;
  React.useEffect(() => {
    if (!readyToLoad) return;
    updateChartState({
      chartKey,
      modelAlias: targetModelAlias,
      queryOption: explorationOptions,
      jsonQuery,
      dataSource: result,
      renderedQuery,
      meta,
      elapsed,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(result), renderedQuery, readyToLoad]);

  return {
    ...response,
    measureName,
    dayDiffValue: maxDayDiff,
  };
};
