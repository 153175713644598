import React from "react";
import { useUserCountHeatmap } from "@src/hooks/ab-testing/use-user-count-heatmap";
import { ChartLayoutWithUserPercent } from "../../layout/chart-layout-with-user-percent";
import { ChartAction, ChartActionLayout } from "../action";
import { RawDataTable } from "./raw-data-table";
import { EChartWrapper } from "../../layout/echart-wrapper";
import ReactECharts from "echarts-for-react";
import { UserCountItemProps } from ".";
import { toTitle } from "@src/helpers/text-helper";

const UserCountByAge: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const { eChartKey, isEmpty, isLoading, option, tableProps, chartDataType, setChartDataType } = useUserCountHeatmap({
    form,
    dimension: "age",
    chartKey,
    chartName: toTitle(chartKey),
    tableLabel: toTitle(chartKey),
  });

  return (
    <ChartLayoutWithUserPercent
      chartDataType={chartDataType}
      setChartDataType={setChartDataType}
      loading={!!isLoading}
      moreActions={
        <ChartActionLayout>
          <ChartAction tabKey={chartKey} tabName={toTitle(chartKey)} form={form} />
        </ChartActionLayout>
      }
      renderRaw={<RawDataTable {...tableProps} />}
      renderChart={
        <EChartWrapper isEmpty={isEmpty}>
          <ReactECharts key={eChartKey} option={option} style={{ height: "100%" }} />
        </EChartWrapper>
      }
    />
  );
};
export const UserCountByAgeMemo = React.memo(UserCountByAge);
