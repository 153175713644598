import React from "react";

export type ChartDataTypeType = "user_count" | "user_percent";
export enum ChartDataTypeEnum {
  USER_COUNT = "user_count",
  USER_PERCENT = "user_percent",
}
export type UseChartLayoutType = {
  chartDataType: ChartDataTypeType;
  setChartDataType: React.Dispatch<React.SetStateAction<ChartDataTypeType>>;
};
export const useChartLayout = (): UseChartLayoutType => {
  const [chartDataType, setChartDataType] = React.useState<ChartDataTypeType>(ChartDataTypeEnum.USER_PERCENT);
  return {
    chartDataType,
    setChartDataType,
  };
};
