import { Empty } from "antd";
import React from "react";
import { IQueryFilter } from "@src/types/ab-testing-exploration";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import dayjs from "dayjs";
import { config } from "@src/config";
import { ABExplorationKeyWords, FilterOperator } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { SimpleReport } from "./report-simple";
import { NestedReport } from "./nested-report";

export const ReportData: React.FC<{ targetKey: string; chartKey: string }> = ({ targetKey, chartKey }) => {
  const { form } = useMetricPerformanceContext();
  const { maxDayDiff, explorationOptions: queryOptions, explorationFilters } = useGetGeneralField({ form });
  const actDateRange = Form.useWatch(ChartFormFieldsEnum.METRIC_TIME_RANGE, form) ?? [];
  const metricApplied = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ?? {};
  const { [ChartFormFieldsEnum.METRIC_FILTER]: filters, [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy } =
    metricApplied;

  const jsonQueryFilter: IQueryFilter[] =
    filters?.rules.map(({ field, operator, value }: { field: string; operator: string; value: string | string[] }) => {
      return {
        member: field,
        operator: operator === "=" ? "equals" : operator,
        values: typeof value === "string" ? [value] : value,
      };
    }) ?? [];

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.DAY_DIFF,
    operator: FilterOperator.BETWEEN,
    values: [0, maxDayDiff],
  });

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.ACT_DATE,
    operator: FilterOperator.BETWEEN,
    values: actDateRange.map((item: string) => dayjs(item).format(config.DATE_FORMAT)),
  });

  jsonQueryFilter.push(...explorationFilters);

  if (Object.keys(metricApplied).length === 0) {
    return <Empty description={"No data"} />;
  }
  if (breakdownBy && breakdownBy.length > 0) {
    return (
      <NestedReport
        targetKey={targetKey}
        chartKey={chartKey}
        jsonQueryFilter={jsonQueryFilter}
        queryOptions={queryOptions}
      />
    );
  }
  return <SimpleReport targetKey={targetKey} jsonQueryFilter={jsonQueryFilter} queryOptions={queryOptions} />;
};
