import { RocketOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Input } from "antd";

export const ApplyMetric: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { handleUpdateMetricTabItems, form } = useMetricPerformanceContext();

  const metricTargetModalWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);
  const metricMeasureWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_MEASURE], form);
  const metricMeasureOptWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS], form);
  const metricSignificanceLevelWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL], form);
  const metricFilterWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_FILTER], form);
  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form);

  const metricAppliedWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form);

  const mapMetricApplied = {
    [ChartFormFieldsEnum.METRIC_TARGET_MODEL]: metricTargetModalWatch,
    [ChartFormFieldsEnum.METRIC_MEASURE]: metricMeasureWatch,
    [ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]: metricMeasureOptWatch,
    [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: metricSignificanceLevelWatch,
    [ChartFormFieldsEnum.METRIC_FILTER]: metricFilterWatch,
    [ChartFormFieldsEnum.METRIC_BREAKDOWN]: metricBreakdownWatch,
  };

  const isConfigChange = JSON.stringify(mapMetricApplied) !== JSON.stringify(metricAppliedWatch);
  let className = "text-gray-500 cursor-pointer";
  if (isConfigChange) {
    className = "text-blue-600 cursor-pointer";
  }

  return (
    <div className="flex justify-center">
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_APPLIED]} hidden>
        <Input />
      </Form.Item>

      <span
        className={className}
        onClick={() => {
          form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], mapMetricApplied);
          handleUpdateMetricTabItems(targetKey, Object.keys(mapMetricApplied));
        }}
      >
        <RocketOutlined className="" /> Apply
      </span>
    </div>
  );
};
