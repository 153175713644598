export const MessageDescription = {
  USER_DISTRIBUTION: {
    INSTALL_DATE: "Distribution of users in each variant based on their app install dates dimension.",
    ONBOARDING_DATE: "Distribution of users in each variant based on their onboarding dates dimension",
    MEDIA_SOURCE: "Distribution of users in each variant based on media sources dimension",
    COUNTRY: "Distribution of users in each variant based on country dimension",
    AGE: "Distribution of users in each variant based on age dimension",
    GENDER: "Distribution of users in each variant based on gender dimension",
    DEVICE_CATEGORY: "Distribution of users in each variant based on device category dimension",
    DEVICE_MODEL: "Distribution of users in each variant based on device modal dimension",
  },
  ENGAGEMENT: {
    PLAY_TIME: {
      TITLE: "This measure includes 2 charts:",
      CONTENT: [
        "Boxplot: Distribution of user playtime (Unit: second) in each variant (Min, P25, Med, P75, Max)",
        "User histogram: Distribution of users in each playtime range with bucket size = 20s (editable in future). We are listing 50 time ranges from the min & the above",
      ],
    },
    TIME_IN_APP: {
      TITLE: "This measure includes 2 charts:",
      CONTENT: [
        "Boxplot: Distribution of user time-in-app (Unit: second) in each variant (Min, P25, Med, P75, Max)",
        "User histogram: Distribution of users in each time-in-app range with bucket size = 50s (editable in future). We are listing 50 time ranges from the min & the above",
      ],
    },
    SESSION_COUNT: {
      TITLE: "This measure includes 2 charts:",
      CONTENT: [
        "Boxplot: Distribution of session in each variant (Min, P25, Med, P75, Max)",
        "User histogram: Distribution of users by session counts. We are listing 20 sessions from the min & the above",
      ],
    },
    EVENT_BREAKDOWN:
      "Distributions of users by event counts. We are listing 50 event counts from the min & the above. Note that time range will affect the event count",
  },
};
