function toKebab(t: string) {
  return t
    .split("")
    .map((letter, index) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join("")
    .replace(/_/g, "-")
    .replace(/\s+/g, "-")
    .replace(/[_\s]+/g, "-");
}

export function toTitle(t: string) {
  return toKebab(t)
    .split("-")
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
